import React, { useState, useEffect } from "react";
import { componentMapper } from "../../utils/componentMapper";
import useSidebarState from "../hooks/useSidebarState";
import { useAuth } from "../../contexts/AuthContext";
import { conditionalRender } from "../../utils/conditionalRender";
import Modal from "../modal/Modal";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import fetchDataAndBuildJson from "../../utils/fetchDataAndBuildJson";
import { Navigate, useLocation } from "react-router-dom";
import AudioPlayer from "../custom_components/AudioPlayer";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { getCookie } from "../../utils/cookieUtils";
import Toast from "../generic_components/Toast";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";

const Page = ({ jsonData, taskName, jsObjectPath }) => {
  const [isShortSidebar, setIsShortSidebar] = useSidebarState(false);
  const { isLoggedIn, logout } = useAuth();
  const [loginStatus, setLoginStatus] = useState(isLoggedIn);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { audioSrc, stopAudio } = useAudioPlayer();
  const [dynamicJsonData, setDynamicJsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const projectId = location.search.slice(11);
  const { localState, updateLocalState } = useLocalState();
  const [infoMessage, setInfoMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchAndBuildJson = async () => {
      if (taskName && jsObjectPath) {
        setIsLoading(true);
        try {
          const data = await fetchDataAndBuildJson(taskName, jsObjectPath, {
            projectId,
          });
          if (isMounted) {
            setDynamicJsonData(data);
          }
        } catch (error) {
          console.error("Error fetching and building JSON:", error);
        } finally {
          if (isMounted) setIsLoading(false);
        }
      } else {
        setDynamicJsonData(jsonData);
        setIsLoading(false);
      }
    };

    fetchAndBuildJson();

    return () => {
      isMounted = false; // Cleanup function to avoid state updates on unmounted components
    };
  }, [taskName, jsObjectPath, projectId, jsonData]);

  // useEffect(() => {
  //   console.log("location.search", location.search);

  //   const queryParams = new URLSearchParams(location.search);
  //   const token = queryParams.get("token");

  //   if (token) {
  //     // Trigger reset password modal if token exists
  //     setModalContent({
  //       inputJson: "resetPassword3.json", // The modal content with the reset password form
  //     });
  //     setShowModal(true);
  //   }
  // }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      // Make the API request to verify the token
      const verifyToken = async () => {
        try {
          const response = await apiService.sendRequest("verifyResetToken", {
            token,
          });

          if (response.success) {
            // If the token is valid, set the token validation status to true
            setModalContent({
              inputJson: "resetPassword3.json", // The modal content with the reset password form
            });
            setShowModal(true);
          } else {
            // If the token is invalid, show an error message
            console.log("Invalid or expired token");
          }
        } catch (error) {
          // Handle any errors
          console.log(error);
          setInfoMessage("Invalid or expired token password reset link.");
          navigate("/");
        }
      };

      verifyToken();
    } else {
      console.log("No token found in the URL.");
    }
  }, [location.search]);

  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      updateLocalState("userNameAndAvatar", null);
      updateLocalState("userAvatar", null);
      updateLocalState("cachedProjects", null);
      stopAudio();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn && localState.showLoginModal) {
      setModalContent({
        inputJson: "signIn.json",
      });
      setShowModal(true);
    }
  }, [localState.showLoginModal]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // For Windows/Linux: Ctrl+I
      // For macOS: Command+I
      if ((event.ctrlKey || event.metaKey) && event.key === "i") {
        event.preventDefault(); // Prevent the default behavior if needed

        if (!isLoggedIn) {
          setModalContent({
            inputJson: "signIn.json",
          });
          setShowModal(true);
        } else {
          // Set the modal content when Ctrl+I or Cmd+I is pressed
          setModalContent({
            name: "customInput",
            props: {
              className: "w-[640px]",
              showCloseButton: false,
              requestData: {
                createProject: true,
              },
              chatPage: false,
            },
          });
          setShowModal(true);
        }
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isLoggedIn]);

  // Utility function to convert a buffer to a Data URL for images
  const bufferToDataURL = (buffer, mimeType) => {
    const binary = Array.from(buffer)
      .map((b) => String.fromCharCode(b))
      .join("");
    const base64 = btoa(binary);
    return `data:${mimeType};base64,${base64}`;
  };

  const renderComponents = (data) => {
    if (!Array.isArray(data)) {
      console.error("Invalid jsonData format or empty array:", data);
      if (taskName === "fetchChatHistory") {
        navigate("/");
      }
      return null;
    }

    return data.map((item, index) => {
      // If item.inputDynamicJson exists, handle dynamic JSON fetching
      if (item.inputDynamicJson) {
        return (
          <DynamicComponent
            key={index}
            taskName={item.inputDynamicJson.taskName}
            jsObjectPath={item.inputDynamicJson.jsObjectPath}
            projectId={projectId}
            renderComponents={renderComponents}
          />
        );
      }

      const conditionalResult = conditionalRender(
        item,
        isShortSidebar,
        setIsShortSidebar,
        renderComponents,
        isLoggedIn,
        location
      );

      if (conditionalResult) {
        return <div key={index}>{conditionalResult}</div>;
      }

      const { name, props, children } = item;

      if (name === "AudioPlayerPlaceholder") {
        return audioSrc ? <AudioPlayer key={index} /> : null;
      }

      const ComponentToRender = componentMapper[name];

      if (!ComponentToRender) {
        console.error(`Component "${name}" not found in componentMapper.`);
        return null;
      }

      const componentProps = {
        ...props,
        ...(name === "div" && props.trigger === "toggleModal"
          ? {
              onClick: () => {
                setModalContent(props.modalContent);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal"
          ? {
              onClick: () => {
                setModalContent(props.modalContent);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal" &&
        props.modalContent1
          ? {
              onClickOption1: () => {
                setModalContent(props.modalContent1);
                setShowModal(true);
              },
            }
          : {}),
        ...((name === "InputWithButton" || name === "button") &&
        props.trigger === "toggleModal" &&
        props.modalContent2
          ? {
              onClickOption2: () => {
                setModalContent(props.modalContent2);
                setShowModal(true);
              },
            }
          : {}),
        ...(props?.trigger === "closeModal"
          ? {
              onClick: () => {
                closeModal(); // Trigger modal close when cancel button is clicked
              },
            }
          : {}),
        ...(name === "InputWithButton"
          ? { closeModal: () => setShowModal(false) }
          : {}),
        ...((props?.userNameAndAvatar || props?.userAvatar) &&
          props.dropdownOptions[1]?.label === "Sign out" && {
            onClickOption: () => {
              setShowModal(true);
              setModalContent(props.dropdownOptions[1]?.modalContent);
            },
          }),
        ...(props?.userNameAndAvatar === true ||
          (props?.userAvatar === true && {
            renderComponents,
          })),
      };

      return (
        <>
          {infoMessage && (
            <Toast
              key={index}
              type="info"
              message={infoMessage}
              onClose={() => setInfoMessage("")}
            />
          )}
          <ComponentToRender key={index} {...componentProps}>
            {children && renderComponents(children)}
          </ComponentToRender>
        </>
      );
    });
  };

  const closeModal = () => {
    setShowModal(false);
    setModalContent(null);
    updateLocalState({ showLoginModal: false });
  };

  return (
    <div>
      {isLoading ? (
        taskName === "fetchChatHistory" ? (
          <>
            {/* Load the JSON file and render its components */}
            {(() => {
              const jsonFile = require(`../../jsonFiles/chatPage.json`);
              return renderComponents(jsonFile);
            })()}
          </>
        ) : location.pathname === "/settings" ? (
          (() => {
            const jsonFile = require(`../../jsonFiles/settings.json`);
            return renderComponents(jsonFile);
          })()
        ) : (
          (() => {
            const jsonFile = require(`../../jsonFiles/loaderPage.json`);
            return renderComponents(jsonFile);
          })()
        )
      ) : (
        renderComponents(dynamicJsonData || jsonData)
      )}
      {showModal && (
        <Modal
          closeModal={closeModal}
          showCloseButton={modalContent?.props?.showCloseButton}
        >
          {modalContent &&
            renderComponents([
              { ...modalContent, props: { ...modalContent.props, closeModal } },
            ])}
        </Modal>
      )}
    </div>
  );
};

const DynamicComponent = ({
  taskName,
  jsObjectPath,
  projectId,
  renderComponents,
}) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { localState, updateLocalState } = useLocalState();

  useEffect(() => {
    let isMounted = true; // Flag to check if component is still mounted

    const fetchDynamicData = async () => {
      try {
        let jsonData;

        // Check if user name and avatar data is already in local state
        if (
          taskName === "fetchUserNameAndAvatar" &&
          localState.userNameAndAvatar
        ) {
          jsonData = await fetchDataAndBuildJson(
            taskName,
            jsObjectPath,
            { localStateData: localState.userNameAndAvatar },
            true
          );
        } else if (taskName === "fetchUserAvatar" && localState.userAvatar) {
          jsonData = await fetchDataAndBuildJson(
            taskName,
            jsObjectPath,
            { localStateData: localState.userAvatar },
            true
          );
        } else {
          jsonData = await fetchDataAndBuildJson(taskName, jsObjectPath, {
            projectId,
            localState,
            updateLocalState,
          });
        }

        if (isMounted) {
          setData(jsonData); // Only update state if component is still mounted
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching dynamic data:", error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false); // Only update state if component is still mounted
        }
      }
    };

    fetchDynamicData();

    return () => {
      isMounted = false; // Cleanup function sets flag to false on unmount
    };
  }, [taskName, jsObjectPath, projectId, localState, updateLocalState]);

  // if (isLoading) return <p>Loading ...</p>;

  return <>{data && renderComponents(data)}</>;
};

export default Page;
