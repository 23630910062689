import React, { useState, useEffect, useRef } from "react";
import { Xmark, PencilToLine } from "@gravity-ui/icons";
import Version from "./Version";
import EditDetailsPanel from "./EditDetailsPanel";
import { useLocalState } from "../../contexts/LocalStateProvider";

const DetailsPanel = ({
  className,
  onClose,
  projectDetails,
  onUpdateDetails,
}) => {
  const { localState } = useLocalState();

  const [isEditing, setIsEditing] = useState(false);
  const [details, setDetails] = useState(
    localState.currentSongDetails || projectDetails
  );
  const [versionHistory, setVersionHistory] = useState([]);
  const versionHistoryRef = useRef(null);

  // Handle updates to details when state changes
  useEffect(() => {
    if (localState.versionHistory) {
      setVersionHistory(localState.versionHistory);
    }
  }, [localState.versionHistory]);

  // Scroll to the bottom when version history is updated
  useEffect(() => {
    if (versionHistoryRef.current) {
      versionHistoryRef.current.scrollTop =
        versionHistoryRef.current.scrollHeight;
    }
  }, [versionHistory]);

  // Handle updates to details when state changes
  useEffect(() => {
    if (localState.currentSongDetails) {
      setDetails(localState.currentSongDetails);
    }
  }, [localState.currentSongDetails]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = (updatedDetails) => {
    setDetails(updatedDetails);
    setIsEditing(false);
    if (onUpdateDetails) {
      onUpdateDetails(updatedDetails);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (localState.currentPlayingResponseId) {
      const versionElement = document.getElementById(
        `version-${localState.currentPlayingResponseId}`
      );
      if (versionElement && versionHistoryRef.current) {
        versionHistoryRef.current.scrollTo({
          top: versionElement.offsetTop - versionHistoryRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [localState.currentPlayingResponseId]);

  return isEditing ? (
    <EditDetailsPanel
      className={className}
      projectDetails={details}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
    />
  ) : (
    <div className={className}>
      <div className="flex items-center justify-between px-4 py-3">
        <h2 className="text-lg font-normal">View Details</h2>
        <button
          className="text-[#878188] hover:text-white p-2 rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
          onClick={onClose}
        >
          <Xmark />
        </button>
      </div>
      <div className="w-full border-t border-t-[#1d1b1d]"></div>

      <div className="flex flex-col items-center mb-5 mx-5 mt-5">
        <div className="relative">
          <img
            src={details.coverImage}
            alt="Cover"
            className="w-24 h-24 object-cover rounded-md mb-3"
          />
        </div>

        <div className="text-center align-center justify-center items-center">
          <div className="flex justify-center items-center gap-1 mb-0.5 flex-wrap">
            <h3 className="font-extralight text-xl truncate max-w-48">
              {details.name}
            </h3>
            <button
              className="text-[#878188] hover:text-white p-2 rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
              onClick={handleEditClick}
            >
              <PencilToLine />
            </button>
          </div>
          <p className="font-extralight self-center text-sm text-[#a5a0a6] w-full max-w-64 truncate">
            {details.description}
          </p>
        </div>
      </div>

      {!isEditing && (
        <div className="">
          <h4 className="font-normal text-base mx-5">Version History</h4>
          <div
            className={`max-h-[calc(100vh-375px)] ${
              versionHistory?.length >= 4 &&
              `scrollable-version overflow-y-auto`
            }`}
            ref={versionHistoryRef}
          >
            {versionHistory.map((version, index) => (
              <Version key={index} version={version} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsPanel;
