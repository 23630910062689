import axios from "axios";
import { getCookie } from "../utils/cookieUtils";
import { deleteCookie } from "../utils/cookieUtils";

const apiService = {
  sendRequest: async (taskName, payload) => {
    const requestData = {
      taskName: taskName,
      payload: payload,
      endpoint: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/execute`,
      method: "POST",
    };

    try {
      const response = await axios.post(requestData.endpoint, requestData);

      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error executing query:", error);
      throw error;
    }
  },

  submitProfile: async (profileData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/submitProfile`,
        profileData
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error submitting profile:", error);
      throw error;
    }
  },

  requestPasswordReset: async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/requestPasswordReset`,
        { email }
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error submitting profile:", error);
      throw error;
    }
  },

  resetPassword: async ({ password, token }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/resetPassword`,
        { password, token }
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error submitting profile:", error);
      throw error;
    }
  },

  // function for handling protected routes with taskName
  handleProtectedRoute: async (taskName, payload) => {
    const token = getCookie("token"); // Get the token from cookies after user sign in

    if (!token) {
      throw new Error("No token found. Authorization required.");
    }

    const endpoint = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/protected`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Add user_id to payload if token exists
    const user_id = getCookie("user_id");
    if (user_id) {
      payload.user_id = user_id;
    }

    const requestData = {
      taskName: taskName,
      payload: payload,
    };

    try {
      const response = await axios.post(endpoint, requestData, { headers });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        deleteCookie("token");
        deleteCookie("user_id");

        window.location.href = "/"; // Redirect to sign-in page
      }

      console.error("Error executing protected route request:", error);
      throw error;
    }
  },

  updateAvatar: async (formData) => {
    const token = getCookie("token");

    if (!token) {
      throw new Error("No token found. Authorization required.");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/updateAvatar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "multipart/form-data", // Ensure the content type is correct for file uploads
          },
        }
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating avatar:", error);
      throw error;
    }
  },

  storeUserMessage: async (formData) => {
    const token = getCookie("token");

    if (!token) {
      throw new Error("No token found. Authorization required.");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/storeUserMessage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "multipart/form-data", // Ensure the content type is correct for file uploads
          },
        }
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating avatar:", error);
      throw error;
    }
  },

  changeAudioCoverImage: async (formData) => {
    const token = getCookie("token");

    if (!token) {
      throw new Error("No token found. Authorization required.");
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/changeAudioCoverImage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.error) {
        throw new Error(response.data.error);
      }
      // console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating audio description:", error);
      throw error;
    }
  },
};

export default apiService;
