import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import Modal from "../modal/Modal";
import InputWithButton from "../generic_components/InputWithButton";
import Toast from "../generic_components/Toast";
import {
  PlayFill,
  PauseFill,
  EllipsisVertical,
  ArrowUpRightFromSquare,
  TrashBin,
} from "@gravity-ui/icons";
import apiService from "../../api/apiService";

const ProjectListView = ({
  imageSrc,
  projectName,
  projectDescription,
  audioSrc,
  songTitle,
  projectId,
  responseId,
  onDelete,
  songDuration,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeSong, setActiveSong] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const navigate = useNavigate();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    setAiResponseId,
  } = useAudioPlayer();

  useEffect(() => {
    if (!currentAudioSrc) {
      setActiveSong(null);
    }
  }, [currentAudioSrc]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle Play/Pause
  const handlePlayPause = useCallback(
    (event) => {
      setAiResponseId(responseId);

      if (
        (dropdownButtonRef.current &&
          dropdownButtonRef.current.contains(event.target)) ||
        (dropdownContainerRef.current &&
          dropdownContainerRef.current.contains(event.target))
      ) {
        return;
      }

      if (!audioSrc) {
        setToastMessage("No audio available for this project.");
        setToastType("info");
        return;
      }

      if (isPlaying && currentAudioSrc === audioSrc) {
        pauseAudio();
        setActiveSong(null);
      } else if (currentAudioSrc === audioSrc) {
        resumeAudio();
        setActiveSong(audioSrc);
      } else {
        playAudio(audioSrc, songTitle, projectDescription, imageSrc);
        setActiveSong(audioSrc);
      }
    },
    [
      audioSrc,
      currentAudioSrc,
      isPlaying,
      pauseAudio,
      playAudio,
      projectName,
      projectDescription,
      imageSrc,
    ]
  );

  // Toggle dropdown
  const handleDropdownToggle = (event) => {
    event.stopPropagation(); // Prevents triggering play/pause when dropdown button is clicked
    setShowDropdown((prev) => !prev);
  };

  // Click outside to close dropdown
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleGoToProject = () => {
    navigate(`/chatPage?projectId=${projectId}`);
  };

  const handleDeleteProject = () => {
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDeleteProject = async () => {
    try {
      const result = await apiService.sendRequest("deleteProject", {
        project_id: projectId,
      });

      console.log(result);

      if (result.success) {
        onDelete(projectId);
      } else {
        setToastMessage(
          result.error || "An error occurred while deleting the project."
        );
        setToastType("error");
      }
    } catch (error) {
      console.log("An error occurred while deleting the project.", error);
      setToastMessage("An error occurred while deleting the project.");
      setToastType("error");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const formatTime = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const truncatedText = (text, length = 50) => {
    if (text.length <= length) return text;
    return text.slice(0, length) + "...";
  };

  return (
    <>
      <div
        id="project-card"
        ref={dropdownRef}
        onClick={handleGoToProject} // Play/Pause on div click
        className={`flex items-center p-1.5 pr-4 m-1.5 rounded-xl cursor-pointer group relative ${
          audioSrc && activeSong === audioSrc
            ? "bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-white"
            : "hover:bg-opacity-10 transition duration-500 hover:bg-white"
        }`}
        style={
          audioSrc && activeSong === audioSrc
            ? {
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
              }
            : {}
        }
      >
        <div className="flex items-center relative overflow-hidden mr-4">
          <img
            src={imageSrc}
            alt={projectName}
            className="w-12 h-12 object-cover rounded-lg"
          />
          <button
            id="project-play-pause-button"
            onClick={(e) => {
              e.stopPropagation();
              handlePlayPause(e);
            }}
            className={`absolute inset-0 flex items-center justify-center text-white transition-transform duration-500 transform group-hover:opacity-100 ${
              isPlaying && currentAudioSrc === audioSrc
                ? "translate-y-0"
                : "translate-y-[70%] group-hover:translate-y-0"
            }`}
          >
            {isPlaying && currentAudioSrc === audioSrc ? (
              <PauseFill className="w-4 h-4" />
            ) : (
              <PlayFill className="w-4 h-4" />
            )}
          </button>
        </div>
        <div className="flex-1 min-w-0">
          <p className="font-medium text-base truncate">{projectName}</p>
          <p className="font-light text-sm text-gray-400">
            {pageWidth <= 768
              ? truncatedText(projectDescription, 24)
              : projectDescription}
          </p>
        </div>
        <div className="hidden md:flex items-center mx-4 flex-grow text-center">
          <span className="font-light text-sm text-white">
            {formatTime(songDuration) || "0:00"}
          </span>
        </div>
        <div className="flex items-center" onClick={handleDropdownToggle}>
          <button
            id="project-options-button"
            className="ml-8 rounded-md text-[#878188] hover:text-white p-1.5 bg-transparent hover:bg-[#232121] relative"
            onClick={handleDropdownToggle} // Opens dropdown on ellipsis button click
            ref={dropdownButtonRef}
          >
            <EllipsisVertical />
          </button>
          {showDropdown && (
            <div
              id="project-options-dropdown"
              ref={dropdownContainerRef}
              className="absolute bottom-[-2rem] right-10 text-center font-normal text-sm text-[#878188] bg-[#100f10] p-1 rounded-lg border border-solid border-[#2c2a2c] w-36 z-10"
            >
              <button
                id="project-go-to-button"
                onClick={handleGoToProject}
                className="flex items-center gap-2 h-8 px-2 w-full hover:text-white rounded-lg hover:bg-[#1d1b1d]"
              >
                <ArrowUpRightFromSquare className="w-4 h-4" /> Go to project
              </button>
              <button
                id="project-delete-button"
                onClick={handleDeleteProject}
                className="flex items-center gap-2 h-8 px-2 w-full hover:text-white rounded-lg hover:bg-[#1d1b1d]"
              >
                <TrashBin className="w-4 h-4" /> Delete project
              </button>
            </div>
          )}
        </div>
      </div>

      {showDeleteModal && (
        <Modal closeModal={closeModal}>
          <div className="max-w-96 pb-3">
            <h3 className="text-left text-base p-3" id="delete-modal-title">
              Delete Project
            </h3>
            <div className="w-full border-t border-t-[#2d2a2d]"></div>
            <InputWithButton
              formClassName="space-y-4 w-full px-3"
              inputFields={[
                {
                  type: "info",
                  content: ["Are you sure you want to delete this project?"],
                  subContent:
                    "This will delete all the generated music and comments. Do you want to proceed?",
                  className:
                    "text-white text-left text-sm font-extralight mb-4 mt-3",
                },
              ]}
              buttonClassName="px-3 mr-3 h-8 text-sm bg-red-600 hover:bg-[#BB2D2D] text-white rounded-full"
              buttonText="Delete"
              onButtonClick={confirmDeleteProject} // Call confirmDeleteProject when delete is clicked
              cancelButtonClassName="px-3 h-8 text-sm rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
              cancelButtonText="Cancel"
              onCancelClick={closeModal}
              closeModal={closeModal}
              cancelButtonId={"delete-project-cancel-button"}
              actionButtonId={"delete-project-confirm-button"}
              requestData={{
                taskName: "deleteProject",
                payload: {
                  project_id: projectId,
                },
              }}
            />
          </div>
        </Modal>
      )}

      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
    </>
  );
};

export default ProjectListView;
