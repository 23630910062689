import React from "react";
import { Xmark } from "@gravity-ui/icons";

const Modal = ({ closeModal, children, showCloseButton = true }) => {
  return (
    <div
      className="text-center fixed inset-0 flex items-center justify-center text-white bg-black bg-opacity-80 z-50"
      onClick={closeModal}
    >
      <div
        className="flex flex-col max-w-full gap-5 bg-[#1d1b1d] rounded-lg border border-solid border-[#4e4a4f] relative"
        onClick={(e) => e.stopPropagation()}
      >
        {showCloseButton && (
          <button
            className="absolute top-2 right-2 p-2 z-10 justify-center rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] text-white"
            onClick={closeModal}
          >
            <Xmark className="w-4 h-4" />
          </button>
        )}
        <div className="px-0 py-0">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
