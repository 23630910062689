import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import routes from "./jsonFiles/routes.json";
import Page from "./components/component_builder/Page";
import { LocalStateProvider } from "./contexts/LocalStateProvider";
import { UserProvider } from "./contexts/UserContext";
import { AudioPlayerProvider } from "./contexts/AudioPlayerContext";
import { AuthProvider, useAuth } from "./contexts/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/" />;
};

const App = () => {
  const [routeComponents, setRouteComponents] = useState([]);

  useEffect(() => {
    const initializeRoutes = async () => {
      const dynamicRoutePromises = routes.map(async (route) => {
        if (route.taskName) {
          return { ...route };
        } else {
          const jsonData = require(`./jsonFiles/${route.json}`);
          return { ...route, jsonData };
        }
      });

      const resolvedRoutes = await Promise.all(dynamicRoutePromises);
      setRouteComponents(resolvedRoutes.filter((route) => route !== null));
    };

    initializeRoutes();
  }, []);

  return (
    <div className="App">
      <Router>
        <UserProvider>
          <AuthProvider>
            <LocalStateProvider>
              <AudioPlayerProvider>
                <Routes>
                  {routeComponents.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={
                        route.taskName ? (
                          <ProtectedRoute>
                            <Page
                              taskName={route.taskName}
                              jsObjectPath={route.jsObjectPath}
                              path={route.path}
                            />
                          </ProtectedRoute>
                        ) : (
                          <Page jsonData={route.jsonData} />
                        )
                      }
                    />
                  ))}
                </Routes>
              </AudioPlayerProvider>
            </LocalStateProvider>
          </AuthProvider>
        </UserProvider>
      </Router>
    </div>
  );
};

export default App;
