import React, { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import ProjectListView from "./ProjectListView"; // Import ProjectListView component
import apiService from "../../api/apiService";
import Toast from "../../components/generic_components/Toast";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { Bars, Rectangles4 } from "@gravity-ui/icons";
import ProjectCardLoader from "./ProjectCardLoader";
import LoadingSongs from "../custom_components/LoadingSongs";

// const LIMIT = 20; // Number of projects to fetch per batch

const ProjectGrid = () => {
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const [isGridView, setIsGridView] = useState(true); // State to toggle between grid and list views
  const { updateLocalState, localState } = useLocalState();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading status
  const [offset, setOffset] = useState(0); // Offset state to keep track of project batches
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const bufferToDataURL = (buffer, mimeType) => {
    const binary = Array.from(new Uint8Array(buffer))
      .map((b) => String.fromCharCode(b))
      .join("");
    const base64 = btoa(binary);
    return `data:${mimeType};base64,${base64}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  // Fetch total number of projects on component mount
  useEffect(() => {
    const fetchTotalProjects = async () => {
      try {
        if (localState.cachedProjects?.length) {
          // If totalProjects exists in local state, use it
          setTotalProjects(localState.cachedProjects.length);
          return;
        }

        const totalData = await apiService.handleProtectedRoute(
          "fetchProjectsCount",
          {}
        );
        setTotalProjects(parseInt(totalData, 10)); // Ensure total is an integer
        // console.log("Total Projects: ", totalData); // Debugging: Check total projects
      } catch (error) {
        console.error("Error fetching total projects count:", error);
        setToastMessage("Failed to load total projects count");
        setToastType("error");
      }
    };

    fetchTotalProjects();
  }, [localState.cachedProjects]);

  // Fetch projects and their latest AI responses in batches
  const fetchProjects = async () => {
    if (loading || projects.length >= totalProjects) return; // Prevent multiple simultaneous fetches and stop if all projects are loaded
    // console.log("Fetching projects with offset:", offset); // Debugging: Check offset
    try {
      setLoading(true);
      const projectData = await apiService.handleProtectedRoute(
        "fetchProjectsWithLastAiResponse",
        { limit: totalProjects, offset: 0 }
      );

      // Process the fetched data and store it in local state
      const projectsWithAiResponses = projectData.map((project) => {
        const projectDetails = {
          ...project,
          imageSrc: project.cover_image_name
            ? `/project-images/${project.cover_image_name}`
            : "/project-images/Album_Art_6.webp", // Fallback image

          audioSrc:
            project.response_id !== null
              ? `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${project.response_id}`
              : null,
          project_description:
            project.music_description?.trim() || "No description available",
        };

        // Store the AI response in local state
        updateLocalState(
          `project_${project.project_id}_aiResponse`,
          projectDetails
        );

        return projectDetails;
      });

      // console.log("Fetched Projects: ", projectsWithAiResponses); // Debugging: Check fetched projects
      setProjects((prev) => {
        const updatedProjects = [...prev, ...projectsWithAiResponses];

        // Cache the projects in localState
        setTimeout(() => {
          updateLocalState("cachedProjects", updatedProjects);
        }, 0);

        return updatedProjects;
      });
      // setOffset((prev) => prev + LIMIT); // Update offset for the next fetch
    } catch (error) {
      console.error("Error fetching projects:", error);
      setToastMessage("Failed to load projects");
      setToastType("error");
    } finally {
      setLoading(false);
    }
  };

  // Continuously fetch projects until all are loaded
  useEffect(() => {
    if (!loading && projects.length < totalProjects) {
      if (
        localState.cachedProjects?.length === totalProjects &&
        !localState.newSongGenerated
      ) {
        // If projects are already cached, use them
        setProjects(localState.cachedProjects);
        return;
      }

      fetchProjects(); // Fetch next batch of projects if more are available
    }
  }, [projects.length, totalProjects, loading, localState.newSongGenerated]); // Run effect whenever projects or totalProjects change, or loading state changes

  // console.log(localState.cachedProjects);

  // Function to handle project deletion and update state
  const handleDeleteProject = (deletedProjectId) => {
    setToastMessage("Project deleted successfully.");
    setToastType("success");
    setProjects((prevProjects) =>
      prevProjects.filter((project) => project.project_id !== deletedProjectId)
    );
  };

  const isMobile = windowWidth < 768; // Adjust this breakpoint as needed

  return (
    <div className="projects-page">
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}

      <div className="hidden md:flex flex-row justify-between px-4 py-3">
        <h1 className="text-lg">My Projects</h1>
        <div className="flex justify-end bg-[#1D1B1D] rounded-lg gap-2 p-2">
          <button
            onClick={() => setIsGridView(true)}
            className={`transition duration-300 ${
              isGridView ? " text-white" : "text-[#878188]"
            }`}
            id="projects-grid-view-button"
          >
            <Rectangles4 />
          </button>
          <button
            onClick={() => setIsGridView(false)}
            className={`transition duration-300 ${
              !isGridView ? " text-white" : "text-[#878188]"
            }`}
            id="projects-list-view-button"
          >
            <Bars />
          </button>
        </div>
      </div>
      <div className="w-full border-t border-t-[#1d1b1d]"></div>

      {/* Toggle buttons for Grid and List View */}

      {/* Render Grid View or List View based on state */}
      {loading && projects.length === 0 ? (
        isGridView && !isMobile ? (
          <ProjectCardLoader itemCount={3} />
        ) : (
          <LoadingSongs itemCount={3} />
        ) // Show loaders while loading
      ) : isGridView && !isMobile ? (
        <div
          className={`project-grid-container max-h-[calc(100vh-${
            isAudioPlaying ? `154px` : `85px`
          })] overflow-y-auto p-4 scrollable-content`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {projects.map((project, index) => (
              <ProjectCard
                key={index}
                imageSrc={project.imageSrc}
                projectName={project.project_name}
                projectDescription={project.project_description}
                audioSrc={project.audioSrc}
                songTitle={project.song_title}
                projectId={project.project_id}
                responseId={project.response_id}
                onDelete={handleDeleteProject}
              />
            ))}
          </div>
          {loading && <p>Loading more projects...</p>}
        </div>
      ) : (
        <div
          className={`max-h-[calc(100vh-${
            isAudioPlaying && !isMobile
              ? `154px`
              : isMobile && isAudioPlaying
              ? `225px`
              : isMobile && !isAudioPlaying
              ? `165px`
              : !isMobile && !isAudioPlaying && `85px`
          })] min-h-[300px] overflow-y-auto scrollable-content`}
        >
          {projects.map((project) => (
            <ProjectListView
              key={project.project_id}
              imageSrc={project.imageSrc}
              projectName={project.project_name}
              projectDescription={project.project_description}
              audioSrc={project.audioSrc}
              songTitle={project.song_title}
              projectId={project.project_id}
              responseId={project.response_id}
              onDelete={handleDeleteProject}
              songDuration={project.music_duration}
            />
          ))}
          {loading && <p>Loading more projects...</p>}
        </div>
      )}
    </div>
  );
};

export default ProjectGrid;
