import React, { useState, useEffect } from "react";
// import Sidebar from "./SideBar";
import SearchBar from "../generic_components/SearchBar";
import DurationDropdown from "../custom_components/DurationDropdown";
import NoResultsFound from "../custom_components/NoResultsFound";
import SongList from "../custom_components/SongList";
import apiService from "../../api/apiService";
import {
  ChevronDown,
  ChevronUp,
  Xmark,
  TrashBin,
  BarsDescendingAlignCenter,
} from "@gravity-ui/icons";
import Dropdown from "../generic_components/Dropdown";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { getCookie } from "../../utils/cookieUtils";
import { useAuth } from "../../contexts/AuthContext";
import LoadingSongs from "./LoadingSongs";

// Utility function to create a Blob URL from binary data
const createBlobURL = (data, mimeType) => {
  const blob = new Blob([new Uint8Array(data)], { type: mimeType });
  return URL.createObjectURL(blob);
};

// Utility function to convert a buffer to a Data URL for images
const bufferToDataURL = (buffer, mimeType) => {
  const binary = Array.from(buffer)
    .map((b) => String.fromCharCode(b))
    .join("");
  const base64 = btoa(binary);
  return `data:${mimeType};base64,${base64}`;
};

const moodOptions = [
  { label: "Dreamy", icon: "🌙" },
  { label: "Uplifting", icon: "🙌" },
  { label: "Bright", icon: "🌟" },
  { label: "Groovy", icon: "💃" },
  { label: "Heavy", icon: "🎸" },
  { label: "Dark", icon: "🌑" },
  { label: "Melancholic", icon: "😢" },
  { label: "Calming", icon: "😌" },
  { label: "Playful", icon: "😜" },
  { label: "Energetic", icon: "⚡" },
  { label: "Romantic", icon: "❤️" },
  { label: "Soft", icon: "🎶" },
  { label: "Cozy", icon: "🔥" },
  { label: "Horror", icon: "👻" },
  { label: "Tense", icon: "😬" },
  { label: "Mysterious", icon: "🕵️‍♂️" },
  { label: "Chill", icon: "❄️" },
  { label: "Grunge", icon: "🎤" },
  { label: "Happy", icon: "😊" },
];

const themeOptions = [
  { label: "Podcast", icon: "🎙️" },
  { label: "Soundtrack", icon: "🎼" },
  { label: "Intro", icon: "🎵" },
  { label: "Opening track", icon: "🎶" },
  { label: "Beats", icon: "🥁" },
  { label: "Background score", icon: "🎧" },
  { label: "Concept", icon: "💡" },
];

const genreOptions = [
  { label: "Pop", icon: "🎤" },
  { label: "Rock", icon: "🎸" },
  { label: "Blues", icon: "🎷" },
  { label: "Bedroom pop", icon: "🛏️" },
  { label: "Shoegaze", icon: "👟" },
  { label: "Jazz", icon: "🎺" },
  { label: "Hip hop", icon: "🎧" },
  { label: "Swing", icon: "🎻" },
  { label: "Ambient", icon: "🌬️" },
  { label: "Ballad", icon: "🎻" },
  { label: "Disco", icon: "🕺" },
  { label: "Funk", icon: "🎹" },
  { label: "Country", icon: "🤠" },
  { label: "Techno", icon: "🎛️" },
  { label: "Chill", icon: "❄️" },
];

const instrumentOptions = [
  { label: "Electric guitar", icon: "🎸" },
  { label: "Electronic drums", icon: "🥁" },
  { label: "Bass", icon: "🎻" },
  { label: "Drums", icon: "🥁" },
  { label: "Saxophone", icon: "🎷" },
  { label: "Upright bass", icon: "🎻" },
  { label: "Piano", icon: "🎹" },
  { label: "Trumpets", icon: "🎺" },
  { label: "Violin", icon: "🎻" },
  { label: "Guitar", icon: "🎸" },
  { label: "Banjo", icon: "🪕" },
  { label: "Synth", icon: "🎛️" },
  { label: "Percussion", icon: "🥁" },
];

const MobileFiltersModal = ({ isVisible, onClose, filters }) => {
  if (!isVisible) return null;

  return (
    <>
      {/* Overlay for background when modal is open */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          isVisible ? "opacity-100" : "opacity-0"
        } z-40`}
      ></div>

      {/* Modal Content */}
      <div
        className={`fixed bottom-0 left-0 w-full bg-[#1D1B1D] z-50 md:hidden h-[80vh] p-4 rounded-t-lg overflow-y-auto scrollable-content transition-transform duration-500 ${
          isVisible ? "translate-y-0" : "translate-y-full"
        }`}
        style={{
          maxHeight: "calc(100vh - 75px)",
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-white">Filters</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-white">
            <Xmark />
          </button>
        </div>
        {filters}
      </div>
    </>
  );
};

const DiscoverPage = () => {
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [selectedMood, setSelectedMood] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [selectedInstrument, setSelectedInstrument] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState([0, 300]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isDropdwon, setIsDropdown] = useState(false);
  const [durationRange, setDurationRange] = useState([0, 300]);
  const { localState, updateLocalState } = useLocalState();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isSearchVisible, setIsSearchVisible] = useState(false); // Add state for search visibility
  const [showMobileFilters, setShowMobileFilters] = useState(false); // Add state for mobile filters visibility
  const mdBreakpoint = 868;

  const [page, setPage] = useState(0); // For pagination
  const [loading, setLoading] = useState(false); // To track if loading more songs
  const [hasMore, setHasMore] = useState(true); // To track if there's more data to load

  const [tempSelectedMood, setTempSelectedMood] = useState([]);
  const [tempSelectedTheme, setTempSelectedTheme] = useState([]);
  const [tempSelectedGenre, setTempSelectedGenre] = useState([]);
  const [tempSelectedInstrument, setTempSelectedInstrument] = useState([]);

  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (pageWidth <= mdBreakpoint) {
      setIsSearchVisible(false);
    }
  }, [pageWidth]);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  // Fetch discover data in sets of 3
  const fetchSongs = async (pageNumber) => {
    console.log("Fetching songs for page:", pageNumber);

    setLoading(true);
    try {
      let userId = null;

      if (isLoggedIn) {
        userId = getCookie("user_id");
      }

      const data = await apiService.sendRequest("fetchDiscoverData", {
        limit: 7,
        offset: pageNumber * 7,
        user_id: isLoggedIn ? userId : null,
      });

      const processedData = data.map((song) => ({
        id: song.discover_id,
        name: song.song_title,
        creator: song.creator || "Unknown Artist",
        duration: "0:30", // Placeholder, adjust based on actual audio length if needed
        audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/${song.discover_id}`,
        image: `/discover-images/${song.cdn_image_url}.jpg`,
        category: song.category,
        genre: song.genre,
        mood: song.mood,
        theme: song.theme,
        instrument: song.instrument,
        description: song.description,
        isLiked: song.is_liked,
        waveformData: song.waveform_data,
      }));

      if (processedData.length > 0) {
        const updatedSongs = [...songs, ...processedData];
        setSongs(updatedSongs);
        setFilteredSongs(updatedSongs);
        updateLocalState("songs", updatedSongs);
      }

      if (processedData.length < 7) {
        setHasMore(false); // No more songs to load
      }
    } catch (error) {
      console.error("Error fetching discover audio:", error);
    } finally {
      setLoading(false);
      checkContentHeight();
    }
  };

  useEffect(() => {
    let isMounted = true;
    // Check localState first before fetching new songs
    if (localState.songs && localState.songs.length >= 19) {
      setSongs(localState.songs);
      setFilteredSongs(localState.songs);
    } else {
      fetchSongs(page); // Fetch initial songs if localState is empty
    }
    return () => {
      isMounted = false; // Cleanup when component unmounts
    };
  }, [page]);

  // Load more songs initially if the content doesn't fill the viewport
  // useEffect(() => {

  //   // Check content height after initial load
  //   checkContentHeight();
  // }, [songs]);

  const checkContentHeight = () => {
    if (hasMore) {
      loadMoreSongs();
    }
  };

  // Load more songs when the user scrolls to the bottom
  const loadMoreSongs = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1); // Load next page
    }
  };

  // Scroll event listener for infinite scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 100
      ) {
        loadMoreSongs();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, hasMore]);

  // Handle filtering logic properly
  useEffect(() => {
    if (songs.length > 0) {
      const filtered = songs.filter((song) => {
        const songDurationInSeconds =
          parseInt(song.duration.split(":")[0]) * 60 +
          parseInt(song.duration.split(":")[1]);

        // Split genre, mood, theme, and instrument into arrays
        const songGenres = song.genre.split(", ");
        const songMoods = song.mood.split(", ");
        const songThemes = song.theme.split(", ");
        const songInstruments = song.instrument.split(", ");

        return (
          (selectedMood.length === 0 ||
            selectedMood.every((mood) => songMoods.includes(mood))) &&
          (selectedTheme.length === 0 ||
            selectedTheme.every((theme) => songThemes.includes(theme))) &&
          (selectedGenre.length === 0 ||
            selectedGenre.every((genre) => songGenres.includes(genre))) &&
          (selectedInstrument.length === 0 ||
            selectedInstrument.every((instrument) =>
              songInstruments.includes(instrument)
            )) &&
          songDurationInSeconds >= durationRange[0] &&
          songDurationInSeconds <= durationRange[1] &&
          song.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

      setFilteredSongs(filtered);
    }
  }, [
    songs,
    selectedMood,
    selectedTheme,
    selectedGenre,
    selectedInstrument,
    selectedDuration,
    durationRange,
    searchTerm,
  ]);

  const clearFilters = () => {
    setSelectedMood([]);
    setSelectedTheme([]);
    setSelectedGenre([]);
    setSelectedInstrument([]);
    setSelectedDuration([0, 300]);
    setSearchTerm("");
    setActiveFilter("");
    setSelectedOptions({});
    setIsDropdown(false);
    setDurationRange([0, 300]);
    handleResetFilters();
  };

  const handleFilterClick = (filter) => {
    // If the filter is already active, set isDropdown to false
    setActiveFilter((prev) => (prev === filter ? "" : filter));

    if (activeFilter === filter) {
      setIsDropdown(false);
    } else {
      // Otherwise, set the active filter and open the dropdown
      setIsDropdown(true);
      if (pageWidth <= mdBreakpoint) {
        // Set temp states with current selected filters for small screens
        setTempSelectedMood([...selectedMood]);
        setTempSelectedTheme([...selectedTheme]);
        setTempSelectedGenre([...selectedGenre]);
        setTempSelectedInstrument([...selectedInstrument]);
      }
    }
  };

  const handleApplyFilters = () => {
    setSelectedMood([...tempSelectedMood]);
    setSelectedTheme([...tempSelectedTheme]);
    setSelectedGenre([...tempSelectedGenre]);
    setSelectedInstrument([...tempSelectedInstrument]);
    setShowMobileFilters(false);
  };

  // Reset the temporary filters (for small screens)
  const handleResetFilters = () => {
    setTempSelectedMood([]);
    setTempSelectedTheme([]);
    setTempSelectedGenre([]);
    setTempSelectedInstrument([]);
  };

  const handleOptionClick = (filter, option) => {
    if (pageWidth <= mdBreakpoint) {
      // For small screens, update the temporary state
      switch (filter) {
        case "Moods":
          setTempSelectedMood((prev) =>
            prev.includes(option)
              ? prev.filter((item) => item !== option)
              : [...prev, option]
          );
          break;
        case "Themes":
          setTempSelectedTheme((prev) =>
            prev.includes(option)
              ? prev.filter((item) => item !== option)
              : [...prev, option]
          );
          break;
        case "Genres":
          setTempSelectedGenre((prev) =>
            prev.includes(option)
              ? prev.filter((item) => item !== option)
              : [...prev, option]
          );
          break;
        case "Instruments":
          setTempSelectedInstrument((prev) =>
            prev.includes(option)
              ? prev.filter((item) => item !== option)
              : [...prev, option]
          );
          break;
        default:
          break;
      }
    } else {
      setSelectedOptions((prev) => ({
        ...prev,
        [filter]: prev[filter]?.includes(option)
          ? prev[filter].filter((item) => item !== option)
          : [...(prev[filter] || []), option],
      }));

      if (filter === "Moods")
        setSelectedMood((prev) => handleTagSelection(prev, option));
      if (filter === "Themes")
        setSelectedTheme((prev) => handleTagSelection(prev, option));
      if (filter === "Genres")
        setSelectedGenre((prev) => handleTagSelection(prev, option));
      if (filter === "Instruments")
        setSelectedInstrument((prev) => handleTagSelection(prev, option));
    }
  };

  const handleTagSelection = (prev, option) => {
    return prev.includes(option)
      ? prev.filter((item) => item !== option)
      : [...prev, option];
  };

  const getFilterLabel = (filter) => {
    const count = selectedOptions[filter]?.length || 0;
    return count > 0 ? `${filter} (${count})` : filter;
  };

  // const renderFilters = () => (
  //   <>
  //     <div className="flex gap-1 w-full flex-wrap">
  //       <div className="flex flex-col w-full">
  //         {/* Always show Moods filter options */}
  //         <div className="flex flex-col mb-4">
  //           <h3 className="text-sm font-normal text-[#ffffff] mb-2">Moods</h3>
  //           <div className="flex flex-wrap gap-2">
  //             {moodOptions.map(({ label, icon }) => (
  //               <button
  //                 key={label}
  //                 onClick={() => handleOptionClick("Moods", label)}
  //                 className={`px-3 py-1 rounded-full text-sm ${
  //                   selectedOptions["Moods"]?.includes(label)
  //                     ? "bg-white text-black"
  //                     : "bg-[#100F10] text-[#878188]"
  //                 }`}
  //               >
  //                 <span>{icon}</span> {/* Display the emoji icon */}
  //                 <span>{label}</span>
  //               </button>
  //             ))}
  //           </div>
  //         </div>

  //         {/* Always show Themes filter options */}
  //         <div className="flex flex-col mb-4">
  //           <h3 className="text-sm font-normal text-[#ffffff] mb-2">Themes</h3>
  //           <div className="flex flex-wrap gap-2">
  //             {themeOptions.map(({ label, icon }) => (
  //               <button
  //                 key={label}
  //                 onClick={() => handleOptionClick("Themes", label)}
  //                 className={`px-3 py-1 rounded-full text-sm ${
  //                   selectedOptions["Themes"]?.includes(label)
  //                     ? "bg-white text-black"
  //                     : "bg-[#100F10] text-[#878188]"
  //                 }`}
  //               >
  //                 <span>{icon}</span> {/* Display the emoji icon */}
  //                 <span>{label}</span>
  //               </button>
  //             ))}
  //           </div>
  //         </div>

  //         {/* Always show Genres filter options */}
  //         <div className="flex flex-col mb-4">
  //           <h3 className="text-sm font-normal text-[#ffffff] mb-2">Genres</h3>
  //           <div className="flex flex-wrap gap-2">
  //             {genreOptions.map(({ label, icon }) => (
  //               <button
  //                 key={label}
  //                 onClick={() => handleOptionClick("Genres", label)}
  //                 className={`px-3 py-1 rounded-full text-sm ${
  //                   selectedOptions["Genres"]?.includes(label)
  //                     ? "bg-white text-black"
  //                     : "bg-[#100F10] text-[#878188]"
  //                 }`}
  //               >
  //                 <span>{icon}</span> {/* Display the emoji icon */}
  //                 <span>{label}</span>
  //               </button>
  //             ))}
  //           </div>
  //         </div>

  //         {/* Always show Instruments filter options */}
  //         <div className="flex flex-col mb-4">
  //           <h3 className="text-sm font-normal text-[#ffffff] mb-2">
  //             Instruments
  //           </h3>
  //           <div className="flex flex-wrap gap-2">
  //             {instrumentOptions.map(({ label, icon }) => (
  //               <button
  //                 key={label}
  //                 onClick={() => handleOptionClick("Instruments", label)}
  //                 className={`px-3 py-1 rounded-full text-sm ${
  //                   selectedOptions["Instruments"]?.includes(label)
  //                     ? "bg-white text-black"
  //                     : "bg-[#100F10] text-[#878188]"
  //                 }`}
  //               >
  //                 <span>{icon}</span> {/* Display the emoji icon */}
  //                 <span>{label}</span>
  //               </button>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     {pageWidth <= mdBreakpoint && (
  //       <div className="flex justify-between mt-4">
  //         <button
  //           onClick={handleResetFilters}
  //           className="bg-[#2c2a2c] text-white px-4 py-2 rounded-md"
  //         >
  //           Reset
  //         </button>
  //         <button
  //           onClick={handleApplyFilters}
  //           className="bg-gradient-to-r from-[#fc10f2] to-[#3078e4] text-white px-4 py-2 rounded-md"
  //         >
  //           Apply
  //         </button>
  //       </div>
  //     )}
  //   </>
  // );
  const renderFilters = () => (
    <>
      <div className="flex gap-1 w-full flex-wrap">
        <div className="flex flex-col w-full">
          {/* Filter options for Moods */}
          <div className="flex flex-col mb-4">
            <h3 className="text-sm font-normal text-[#ffffff] mb-2">Moods</h3>
            <div className="flex flex-wrap gap-2">
              {moodOptions.map(({ label, icon }) => (
                <button
                  key={label}
                  onClick={() => handleOptionClick("Moods", label)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    (
                      pageWidth <= mdBreakpoint
                        ? tempSelectedMood.includes(label)
                        : selectedMood.includes(label)
                    )
                      ? "bg-white text-black"
                      : "bg-[#100F10] text-[#878188]"
                  }`}
                >
                  <span>{icon}</span> {/* Display the emoji icon */}
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Filter options for Themes */}
          <div className="flex flex-col mb-4">
            <h3 className="text-sm font-normal text-[#ffffff] mb-2">Themes</h3>
            <div className="flex flex-wrap gap-2">
              {themeOptions.map(({ label, icon }) => (
                <button
                  key={label}
                  onClick={() => handleOptionClick("Themes", label)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    (
                      pageWidth <= mdBreakpoint
                        ? tempSelectedTheme.includes(label)
                        : selectedTheme.includes(label)
                    )
                      ? "bg-white text-black"
                      : "bg-[#100F10] text-[#878188]"
                  }`}
                >
                  <span>{icon}</span> {/* Display the emoji icon */}
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Filter options for Genres */}
          <div className="flex flex-col mb-4">
            <h3 className="text-sm font-normal text-[#ffffff] mb-2">Genres</h3>
            <div className="flex flex-wrap gap-2">
              {genreOptions.map(({ label, icon }) => (
                <button
                  key={label}
                  onClick={() => handleOptionClick("Genres", label)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    (
                      pageWidth <= mdBreakpoint
                        ? tempSelectedGenre.includes(label)
                        : selectedGenre.includes(label)
                    )
                      ? "bg-white text-black"
                      : "bg-[#100F10] text-[#878188]"
                  }`}
                >
                  <span>{icon}</span> {/* Display the emoji icon */}
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Filter options for Instruments */}
          <div className="flex flex-col mb-4">
            <h3 className="text-sm font-normal text-[#ffffff] mb-2">
              Instruments
            </h3>
            <div className="flex flex-wrap gap-2">
              {instrumentOptions.map(({ label, icon }) => (
                <button
                  key={label}
                  onClick={() => handleOptionClick("Instruments", label)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    (
                      pageWidth <= mdBreakpoint
                        ? tempSelectedInstrument.includes(label)
                        : selectedInstrument.includes(label)
                    )
                      ? "bg-white text-black"
                      : "bg-[#100F10] text-[#878188]"
                  }`}
                >
                  <span>{icon}</span> {/* Display the emoji icon */}
                  <span>{label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Apply and Reset buttons for small screens */}
          {pageWidth <= mdBreakpoint && (
            <div className="flex justify-between mt-4 w-full">
              <button
                onClick={handleResetFilters}
                className="flex-1 text-[#878188] px-2 py-1 font-medium rounded-md hover:text-white"
              >
                Reset
              </button>
              <button
                onClick={handleApplyFilters}
                className={`flex-1 px-4 py-2 font-medium rounded-md ${
                  getTempSelectedCount() > 0
                    ? "bg-white text-black"
                    : "bg-[#2c2a2c] text-[#878188]"
                }`}
                disabled={getTempSelectedCount() === 0}
              >
                {getTempSelectedCount() > 0
                  ? `Apply (${getTempSelectedCount()})`
                  : "Apply"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const getTempSelectedCount = () => {
    return (
      tempSelectedMood.length +
      tempSelectedTheme.length +
      tempSelectedGenre.length +
      tempSelectedInstrument.length
    );
  };

  // Function to get applied filter count
  const getAppliedFilterCount = () => {
    return (
      selectedMood.length +
      selectedTheme.length +
      selectedGenre.length +
      selectedInstrument.length
    );
  };

  return (
    <div className="flex w-full h-full">
      <MobileFiltersModal
        isVisible={showMobileFilters}
        onClose={() => setShowMobileFilters(false)}
        filters={renderFilters()}
      />
      <div className="flex-1 w-full h-full">
        <div className="flex flex-row justify-between items-center w-full px-3 py-3">
          {!isSearchVisible && (
            <div className="md:hidden">
              <button
                id="filter-button"
                onClick={() => setShowMobileFilters(true)}
                className={`flex ${
                  getAppliedFilterCount() > 0 ? `text-white` : `text-[#878188]`
                } gap-2 flex-row  items-center h-9 px-3 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white`}
              >
                <BarsDescendingAlignCenter />
                <span>Filter</span>
                {getAppliedFilterCount() > 0 && (
                  <span className="">{getAppliedFilterCount()}</span>
                )}
              </button>
            </div>
          )}
          <div className="flex flex-row justify-between items-start w-full">
            {!isSearchVisible && (
              <div className="flex gap-1 w-full flex-wrap">
                <div className="hidden md:flex">
                  <button
                    id="moods-filter-button"
                    onClick={() => handleFilterClick("Moods")}
                    className={`flex flex-row  items-center h-9 px-3 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white ${
                      activeFilter === "Moods" && " text-white"
                    }`}
                  >
                    {getFilterLabel("Moods")}
                    {activeFilter === "Moods" ? (
                      <ChevronUp className="ml-2" />
                    ) : (
                      <ChevronDown className="ml-2" />
                    )}
                  </button>
                  <button
                    id="themes-filter-button"
                    onClick={() => handleFilterClick("Themes")}
                    className={`flex flex-row items-center h-9 px-3 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white ${
                      activeFilter === "Themes" && " text-white"
                    }`}
                  >
                    {getFilterLabel("Themes")}
                    {activeFilter === "Themes" ? (
                      <ChevronUp className="ml-2" />
                    ) : (
                      <ChevronDown className="ml-2" />
                    )}
                  </button>
                  <button
                    id="genres-filter-button"
                    onClick={() => handleFilterClick("Genres")}
                    className={`flex flex-row items-center h-9 px-3 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white ${
                      activeFilter === "Genres" && " text-white"
                    }`}
                  >
                    {getFilterLabel("Genres")}
                    {activeFilter === "Genres" ? (
                      <ChevronUp className="ml-2" />
                    ) : (
                      <ChevronDown className="ml-2" />
                    )}
                  </button>
                  <button
                    id="instruments-filter-button"
                    onClick={() => handleFilterClick("Instruments")}
                    className={`flex flex-row items-center h-9 px-3 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white ${
                      activeFilter === "Instruments" && " text-white"
                    }`}
                  >
                    {getFilterLabel("Instruments")}
                    {activeFilter === "Instruments" ? (
                      <ChevronUp className="ml-2" />
                    ) : (
                      <ChevronDown className="ml-2" />
                    )}
                  </button>
                </div>
                <div className="flex flex-row items-center h-9 px-0 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white">
                  <Dropdown
                    buttonLabel="Duration"
                    className="bg-[#100f10] rounded md:w-72 w-60"
                    customContent={
                      <DurationDropdown onApply={setDurationRange} />
                    }
                  />
                </div>
                <div className="flex flex-row items-center h-9 px-0 text-sm font-normal rounded-full text-[#878188] hover:bg-zinc-900 hover:text-white  ">
                  <Dropdown
                    options={[
                      { label: "Newest", id: "filter-option-newest" },
                      {
                        label: "Alphabetical (A-Z)",
                        id: "filter-option-alphabetical",
                      },
                      { label: "Oldest", id: "filter-option-oldest" },
                    ]}
                    buttonLabel="Sort by"
                    className="bg-[#100f10] py-1 px-1 rounded-lg w-40"
                  />
                </div>
              </div>
            )}
            <div className={`md:flex md:w-auto ${isSearchVisible && `w-full`}`}>
              <SearchBar
                placeholder="Search by song name, creator"
                className="bg-transparent md:w-64 text-sm font-extralight placeholder-[#878188] h-9 rounded-full border border-solid border-[#878188]"
                value={searchTerm}
                onSearch={setSearchTerm}
                onClear={() => setSearchTerm("")}
                onVisibilityChange={setIsSearchVisible}
              />
            </div>
          </div>
        </div>

        {/* Filter Options with Clear All Filters */}
        <div className="flex justify-between w-full px-3 mb-1">
          <div className="flex flex-wrap gap-1">
            {activeFilter === "Moods" && (
              <>
                {moodOptions.map((mood) => (
                  <div
                    key={mood.label}
                    className="p-px text-[#878188] hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 rounded-full"
                  >
                    <button
                      id={`mood-option-${mood.label.replace(" ", "-")}`}
                      onClick={() => handleOptionClick("Moods", mood.label)}
                      className={`flex items-center gap-2 h-8 px-2 text-sm font-normal rounded-full border border-[#2c2a2c] ${
                        selectedOptions["Moods"]?.includes(mood.label)
                          ? "bg-white text-black"
                          : "bg-[#100f10]"
                      }`}
                    >
                      <span>{mood.icon}</span>
                      <span>{mood.label}</span>
                      {selectedOptions["Moods"]?.includes(mood.label) && (
                        <Xmark
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent button click event
                            handleOptionClick("Moods", mood.label);
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </button>
                  </div>
                ))}
              </>
            )}

            {activeFilter === "Themes" && (
              <>
                {themeOptions.map((theme) => (
                  <div
                    key={theme.label}
                    className="p-px text-[#878188] hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 rounded-full"
                  >
                    <button
                      id={`theme-option-${theme.label.replace(" ", "-")}`}
                      onClick={() => handleOptionClick("Themes", theme.label)}
                      className={`flex items-center gap-2 h-8 px-2 text-sm font-normal rounded-full border border-[#2c2a2c] ${
                        selectedOptions["Themes"]?.includes(theme.label)
                          ? "bg-white text-black"
                          : "bg-[#100f10]"
                      }`}
                    >
                      <span>{theme.icon}</span>
                      <span>{theme.label}</span>
                      {selectedOptions["Themes"]?.includes(theme.label) && (
                        <Xmark
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent button click event
                            handleOptionClick("Themes", theme.label);
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </button>
                  </div>
                ))}
              </>
            )}

            {activeFilter === "Genres" && (
              <>
                {genreOptions.map((genre) => (
                  <div
                    key={genre.label}
                    className="p-px text-[#878188] hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 rounded-full"
                  >
                    <button
                      id={`genre-option-${genre.label.replace(" ", "-")}`}
                      onClick={() => handleOptionClick("Genres", genre.label)}
                      className={`flex items-center gap-2 h-8 px-2 text-sm font-normal rounded-full border border-[#2c2a2c] ${
                        selectedOptions["Genres"]?.includes(genre.label)
                          ? "bg-white text-black"
                          : "bg-[#100f10]"
                      }`}
                    >
                      <span>{genre.icon}</span>
                      <span>{genre.label}</span>
                      {selectedOptions["Genres"]?.includes(genre.label) && (
                        <Xmark
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent button click event
                            handleOptionClick("Genres", genre.label);
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </button>
                  </div>
                ))}
              </>
            )}

            {activeFilter === "Instruments" && (
              <>
                {instrumentOptions.map((instrument) => (
                  <div
                    key={instrument.label}
                    className="p-px text-[#878188] hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 rounded-full"
                  >
                    <button
                      id={`instrument-option-${instrument.label.replace(
                        " ",
                        "-"
                      )}`}
                      onClick={() =>
                        handleOptionClick("Instruments", instrument.label)
                      }
                      className={`flex items-center gap-2 h-8 px-2 text-sm font-normal rounded-full border border-[#2c2a2c] ${
                        selectedOptions["Instruments"]?.includes(
                          instrument.label
                        )
                          ? "bg-white text-black"
                          : "bg-[#100f10]"
                      }`}
                    >
                      <span>{instrument.icon}</span>
                      <span>{instrument.label}</span>
                      {selectedOptions["Instruments"]?.includes(
                        instrument.label
                      ) && (
                        <Xmark
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent button click event
                            handleOptionClick("Instruments", instrument.label);
                          }}
                          className="cursor-pointer"
                        />
                      )}
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>

          {/* Clear all filters button with space */}
          {(Object.values(selectedOptions).some(
            (options) => options.length > 0
          ) ||
            (pageWidth < 768 && getAppliedFilterCount() > 0)) && (
            <div
              className={`flex ${
                pageWidth < 768 ? "justify-start" : "justify-between"
              } md:w-auto w-full md:px-3 mb-1 text-sm font-normal`}
            >
              <button
                id="clear-all-filters-button"
                onClick={clearFilters}
                className="flex items-center whitespace-nowrap bg-transparent text-[#878188] hover:bg-zinc-900 hover:text-white rounded-full h-9 px-3 gap-1"
              >
                <TrashBin className="mr-1" />
                Clear all filters
              </button>
            </div>
          )}

          {/* <p>{pageWidth}</p> */}
        </div>
        {filteredSongs?.length > 0 ? (
          <div
            className={`overflow-y-auto 
            ${
              activeFilter !== "Themes" &&
              activeFilter !== "" &&
              !isAudioPlaying
                ? `max-h-[calc(100vh-14rem)]`
                : isAudioPlaying && `max-h-[calc(100vh-18rem)]`
            } ${activeFilter == "Themes" && `max-h-[calc(100vh-11rem)]`}
            ${
              activeFilter == "Themes" &&
              isAudioPlaying &&
              `max-h-[calc(100vh-15.5rem)]`
            } ${
              activeFilter == "" &&
              isAudioPlaying &&
              pageWidth <= 1124 &&
              `max-h-[calc(100vh-15rem)]`
            } ${
              activeFilter == "" &&
              isAudioPlaying &&
              pageWidth > 1124 &&
              `max-h-[calc(100vh-210px)]`
            }
             ${
               isAudioPlaying
                 ? `max-h-[calc(100vh-13rem)]`
                 : `max-h-[calc(100vh-9rem)]`
             } ${
              pageWidth <= 1124 && isAudioPlaying
                ? `max-h-[calc(100vh-250px)]`
                : pageWidth <= 1124 &&
                  !isAudioPlaying &&
                  `max-h-[calc(100vh-12rem)]`
            } scrollable-content
            ${pageWidth <= 768 ? "small-screen" : ""}
    ${isAudioPlaying && pageWidth <= 768 ? "is-audio-playing" : ""}`}
          >
            <SongList songs={filteredSongs} />
          </div>
        ) : !loading ? (
          <div className="flex items-center justify-center w-full h-3/4">
            <NoResultsFound onClearFilters={clearFilters} />
          </div>
        ) : (
          <LoadingSongs itemCount={7} />
        )}
        {/* {!hasMore && <p>No more songs to load</p>} */}
      </div>
    </div>
  );
};

export default DiscoverPage;
