import React, { useState, useEffect } from "react";
import { Check, ArrowLeft, PencilToLine, Xmark } from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";
import { useLocalState } from "../../contexts/LocalStateProvider"; // Import useLocalState

const EditDetailsPanel = ({
  className,
  projectDetails,
  onSave,
  onCancel,
  onClose,
}) => {
  const { localState, updateLocalState } = useLocalState(); // Use useLocalState to update the state
  const [editedDetails, setEditedDetails] = useState({
    name: projectDetails?.name || localState.currentSongDetails?.name,
    description:
      projectDetails?.description || localState.currentSongDetails?.description,
    coverImage:
      projectDetails?.coverImage || localState.currentSongDetails?.coverImage,
    coverImageFile: null, // To store the selected file
  });

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const { name, description } = editedDetails;
    setIsSaveEnabled(
      (name?.trim() !== "" && name !== projectDetails.name) ||
        (description?.trim() !== "" &&
          description !== projectDetails.description) ||
        editedDetails.coverImageFile !== null
    );
  }, [editedDetails, projectDetails]);

  const handleSaveClick = async () => {
    try {
      let hasChanges = false;

      // Check for changes in name
      if (editedDetails.name !== projectDetails.name) {
        hasChanges = true;
        const namePayload = {
          ai_response_id: projectDetails.ai_response_id,
          song_title: editedDetails.name,
        };
        await apiService.sendRequest("changeAudioTitle", namePayload);
      }

      // Check for changes in description
      if (editedDetails.description !== projectDetails.description) {
        hasChanges = true;
        const descriptionPayload = {
          ai_response_id: projectDetails.ai_response_id,
          music_description: editedDetails.description,
        };
        await apiService.sendRequest(
          "changeAudioDescription",
          descriptionPayload
        );
      }

      // Check for changes in cover image
      if (editedDetails.coverImageFile) {
        hasChanges = true;
        const formData = new FormData();
        console.log("ai response id", projectDetails);

        formData.append("ai_response_id", projectDetails.ai_response_id);
        formData.append("coverImage", editedDetails.coverImageFile);

        await apiService.changeAudioCoverImage(formData);
      }

      if (hasChanges) {
        setToastMessage("Details updated successfully");
        setToastType("success");

        // Update local state to notify AIChatBox and to retain updated project details
        const updatedProjectDetails = {
          name: editedDetails.name,
          description: editedDetails.description,
          coverImage: editedDetails.coverImage,
          ai_response_id: projectDetails.ai_response_id,
        };

        if (onSave) {
          onSave(updatedProjectDetails); // Update the parent component with the new details
        }

        updateLocalState("editedSongDetails", updatedProjectDetails);
      } else {
        setToastMessage("No changes to save");
        setToastType("info");
      }
    } catch (error) {
      console.error("Error during save:", error);
      setToastMessage("An error occurred while saving");
      setToastType("error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setEditedDetails({
        ...editedDetails,
        coverImage: URL.createObjectURL(file), // Update the preview
        coverImageFile: file, // Store the file for upload
      });
    }
  };

  return (
    <div
      className={`flex flex-col items-center self-stretch rounded-lg border border-solid border-[#2c2a2c] ${className}`}
    >
      <div
        id="edit-details-header"
        className="flex items-center self-stretch px-4 py-3 border-solid border-[#2c2a2c]"
      >
        <div className="flex items-center gap-3 grow">
          <button
            id="back-button-edit-details"
            className="w-8 h-8 flex items-center gap-1 bg-[#2c2a2c] px-2 rounded-full"
            onClick={onCancel}
          >
            <ArrowLeft className="w-4 h-4 text-white" />
          </button>
          <span className="font-normal text-lg text-white">Edit Details</span>
        </div>
        <button
          id="close-button-edit-details"
          className="text-[#878188] hover:text-white p-2 rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
          onClick={onClose}
        >
          <Xmark />
        </button>
      </div>
      <div className="w-full border-t border-t-[#1d1b1d]"></div>

      <div className="flex flex-col self-stretch">
        <div className="flex flex-col gap-5 self-stretch grow px-5 pt-5">
          <div
            id="cover-image-container"
            className="relative w-24 h-24 flex justify-end items-end gap-2.5 object-cover p-2 rounded-xl bg-cover bg-center"
            style={{ backgroundImage: `url(${editedDetails.coverImage})` }}
          >
            <>
              <input
                id="cover-image-upload"
                type="file"
                accept="image/*"
                className="absolute opacity-0 cursor-pointer"
                onChange={handleImageChange}
              />
              <div className="w-8 h-8 flex justify-center items-center gap-1 bg-white px-2 rounded-full">
                <PencilToLine className="w-4 h-4 text-[#100f10]" />
              </div>
            </>
          </div>
          <div className="flex flex-col gap-2 self-stretch">
            <span className="font-normal text-sm text-white">
              Title of Song
            </span>
            <input
              id="song-title-input"
              type="text"
              name="name"
              value={editedDetails.name}
              onChange={handleInputChange}
              className="object-cover flex justify-center items-center self-stretch h-9 px-4 rounded-full border border-solid border-[#878188] text-white font-extralight text-sm bg-transparent"
            />
          </div>
          <div className="flex flex-col gap-2 self-stretch">
            <span className="font-normal text-sm text-white">Description</span>
            <textarea
              id="song-description-textarea"
              name="description"
              value={editedDetails.description}
              onChange={handleInputChange}
              rows={isAudioPlaying ? (pageWidth <= 999 ? 2 : 3) : 7}
              className="flex justify-center items-center self-stretch px-3 pt-2 scrollable-content rounded-xl border border-solid border-[#878188] font-extralight text-sm text-white bg-transparent resize-none overflow-auto" // 'resize-none' prevents manual resizing, 'overflow-auto' adds scrollbars when needed
              // style={{ minHeight: "calc(1em * 10)" }}
            />
          </div>
        </div>
        <div
          id="save-button-container"
          className="flex justify-end p-5 self-stretch rounded-full"
        >
          <button
            id="save-details-button"
            className={`h-9 flex items-center gap-1 px-3 rounded-full ${
              isSaveEnabled
                ? "bg-white text-black"
                : "bg-[#4e4a4f] text-[#878188]"
            }`}
            onClick={handleSaveClick}
            disabled={!isSaveEnabled}
          >
            <span className="font-normal text-sm">Save</span>
          </button>
        </div>
      </div>
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};

export default EditDetailsPanel;
