import React, { useEffect } from "react";
import { CircleExclamation, Xmark } from "@gravity-ui/icons";

const Toast = ({ type, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000); // Auto close after 3 seconds
    return () => clearTimeout(timer);
  }, [onClose]);

  let bgColor;
  switch (type) {
    case "success":
      bgColor = "bg-[#002C0D] text-[#BEFFD1]";
      break;
    case "error":
      bgColor = "bg-[#340000] text-[#FFC7C7]";
      break;
    case "info":
      bgColor = "bg-neutral-900";
      break;
    default:
      bgColor = "bg-gray-800";
  }

  return (
    <div
      className={`fixed text-sm font-normal top-4 left-1/2 transform -translate-x-1/2 ${bgColor} pl-4 pr-1 py-1 gap-2 rounded-lg flex items-center w-full max-w-96`}
    >
      <CircleExclamation />
      <span className="w-full text-left">{message}</span>
      <button
        className="ml-4 p-2 text-[#878188] hover:text-white"
        onClick={onClose}
      >
        <Xmark />
      </button>
    </div>
  );
};

export default Toast;
