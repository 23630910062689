import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../modal/Modal"; // Import Modal component
import { componentMapper } from "../../utils/componentMapper"; // Import componentMapper
import {
  FaTiktok,
  FaPlus,
  FaBars,
  FaSearch,
  FaSignInAlt,
  FaUserPlus,
  FaQuestionCircle,
  FaUserAlt,
  FaHome,
} from "react-icons/fa";
import { RiExpandRightLine, RiContractLeftLine } from "react-icons/ri";
import { GrProjects } from "react-icons/gr";
import { PiNotePencil, PiSquaresFour } from "react-icons/pi";
import {
  House,
  Globe,
  ArrowRightFromSquare,
  MusicNote,
  ArrowLeftFromLine,
  CircleQuestionFill,
  Plus,
  Circles4Square,
  ArrowRightFromLine,
  ChevronDown,
  ChevronUp,
  Person,
  Rectangles4,
  Rocket,
  CircleExclamation,
  Keyboard,
} from "@gravity-ui/icons";
import { useAuth } from "../../contexts/AuthContext";
import { useLocalState } from "../../contexts/LocalStateProvider";

const iconComponents = {
  FaTiktok,
  FaPlus,
  FaBars,
  FaSearch,
  FaSignInAlt,
  FaUserPlus,
  FaQuestionCircle,
  RiExpandRightLine,
  RiContractLeftLine,
  GrProjects,
  FaUserAlt,
  PiNotePencil,
  House,
  Globe,
  ArrowRightFromSquare,
  MusicNote,
  ArrowLeftFromLine,
  CircleQuestionFill,
  Plus,
  PiSquaresFour,
  Circles4Square,
  ArrowRightFromLine,
  ChevronDown,
  ChevronUp,
  Person,
  Rectangles4,
  Rocket,
  CircleExclamation,
  Keyboard,
};

export default function Button(props) {
  const {
    caption,
    path,
    url,
    className,
    icon,
    btnStyle,
    imgStyle,
    onClick,
    image,
    dropdownOptions,
    dropdownStyle,
    isSuggestion,
    iconStyle,
    svg,
    dropdownClick,
    musicEmoji,
    closeModal,
    createNew,
    trigger,
    renderComponents,
    onClickOption,
    signOut,
    id,
    userAvatar,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { updateLocalState } = useLocalState();

  const IconComponent = icon ? iconComponents[icon] : null;
  const isActive = location.pathname === path;
  const activeClassName = "text-white";

  const handleClick = (e) => {
    if (trigger === "closeModal") {
      // Close the modal if the trigger is closeModal
      onClick && onClick();

      if (signOut) {
        // Track the event with Google Analytics
        gtag("event", "sign_out", {
          event_category: "User Interaction",
          event_label: "Sign Out Confirm Button",
          value: 1,
        });
        logout();
        navigate("/");
      }
    }

    if (dropdownOptions) {
      e.stopPropagation();
      setDropdownVisible(!dropdownVisible);
    }

    if (onClick === "handleSignOut") {
      logout();
      navigate("/");
      return;
    }

    if (onClick) {
      onClick(e);
    }

    if (isSuggestion) {
      updateLocalState("suggestionClicked", caption);
    }
    if (path) {
      navigate(path);
    }

    if (url) {
      window.location.href = url; // Navigate to external URL
    }
  };

  const handleDropdownClick = (option) => {
    if (option?.url) {
      window.location.href = option.url; // Navigate to external URL
    } else if (path) {
      navigate(path); // Navigate within the app
    } else if (option.path) {
      navigate(option.path);
    } else if (onClickOption) {
      onClickOption();
    }
    setDropdownVisible(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const DropdownIcon = dropdownVisible ? ChevronUp : ChevronDown;

  const userAgent = navigator.userAgent.toLowerCase();

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        id={id}
        style={btnStyle}
        className={`${className} ${isActive && activeClassName}`}
        onClick={handleClick}
      >
        <>
          {image && (
            <img
              style={imgStyle}
              src={image}
              alt={caption}
              className="w-6 h-6"
            />
          )}
          {svg && <img src={svg} alt="icon" className={`${iconStyle}`} />}
          {IconComponent && <IconComponent className={`${iconStyle}`} />}
          {musicEmoji && (
            <div className="ml-1 h-7 w-7 px-1.5 py-1 [background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] rounded-sm text-center text-sm">
              {musicEmoji}
            </div>
          )}
          <div className="truncate max-w-80">{caption}</div>
        </>
        {dropdownClick && !userAvatar && (
          <DropdownIcon className={`${iconStyle} absolute right-7`} />
        )}
        {createNew && (
          <div className="absolute right-3 text-xs flex items-center">
            {userAgent.includes("win") ? (
              <>
                <img
                  src="/ctrl.svg"
                  alt="Control Key Icon"
                  className="mr-0.5"
                />
                <img src="/keyword-I.svg" alt="Keyword I Icon" />
              </>
            ) : (
              <>
                <img src="/cmd.svg" alt="Command Key Icon" className="mr-0.5" />
                <img src="/keyword-I.svg" alt="Keyword I Icon" />
              </>
            )}
          </div>
        )}
      </button>
      {dropdownVisible && dropdownOptions && (
        <div className={dropdownStyle}>
          {dropdownOptions.map((option) => {
            const OptionIcon = iconComponents[option.icon];
            return (
              <div
                id={option.id}
                key={option.label}
                onClick={() => handleDropdownClick(option)}
                className={`px-2 py-2 cursor-pointer rounded-lg flex items-center gap-2 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] ${option.textStyle}`}
              >
                {OptionIcon && <OptionIcon className="w-4 h-4" />}
                {option.img && <img src={option.img} alt={option.label} />}
                {option.label}
              </div>
            );
          })}
        </div>
      )}
      {/* {showModal &&
        modalContent &&
        renderModalContent(modalContent, renderComponents)} */}
    </div>
  );
}

function renderModalContent(modalContent, renderComponents) {
  return renderComponents([modalContent]);
  // const { name, props, children } = modalContent;
  // const ComponentToRender = componentMapper[name];

  // if (!ComponentToRender) {
  //   console.error(`Component "${name}" not found in componentMapper.`);
  //   return null;
  // }

  // return (
  //   <ComponentToRender {...props}>
  //     {children &&
  //       children.map((child, index) =>
  //         renderModalContent({
  //           name: child.name,
  //           props: { ...child.props, key: index },
  //           children: child.children,
  //         })
  //       )}
  //   </ComponentToRender>
  // );
}
