import Button from "../components/generic_components/Button";
import CustomInput from "../components/custom_components/CustomInput";
import InputWithButton from "../components/generic_components/InputWithButton";
import EditableField from "../components/custom_components/EditableField";
import ProjectCard from "../components/generic_components/ProjectCard";
import ProjectGrid from "../components/generic_components/ProjectGrid";
import AudioPlayer from "../components/custom_components/AudioPlayer";
import Dropdown from "../components/generic_components/Dropdown";
import DiscoverPage from "../components/custom_components/DiscoverPage";
import Header from "../components/custom_components/Header";
import ChatInterface from "../components/custom_components/ChatInterface";
import DetailsPanel from "../components/custom_components/DetailsPanel";
import ProjectPage from "../components/custom_components/ProjectPage";
import ChatBoxLoader from "../components/custom_components/ChatBoxLoader";
import Image from "../components/generic_components/Image";

export const componentMapper = {
  Header: Header,
  ChatInterface: ChatInterface,
  DiscoverPage: DiscoverPage,
  DetailsPanel: DetailsPanel,
  ProjectPage: ProjectPage,
  Dropdown: Dropdown,
  button: Button,
  customInput: CustomInput,
  InputWithButton: InputWithButton,
  editableField: EditableField,
  ProjectGrid: ProjectGrid,
  ProjectCard: ProjectCard,
  AudioPlayer: AudioPlayer,
  ChatBoxLoader: ChatBoxLoader,
  h1: (props) => <h1 {...props}>{props.text}</h1>,
  h2: (props) => <h2 {...props}>{props.text}</h2>,
  h3: (props) => <h3 {...props}>{props.text}</h3>,
  p: (props) => <p {...props}>{props.text}</p>,
  div: (props) => <div {...props}>{props.children}</div>,
  span: (props) => <span {...props}>{props.text}</span>,
  image: Image,
  select: (props) => <select {...props}></select>,
  svg: (props) => <svg {...props}></svg>,
  b: (props) => <b {...props}></b>,
};
