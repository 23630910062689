import React, { useState, useRef, useEffect } from "react";
import {
  PlayFill,
  PauseFill,
  Ellipsis,
  ArrowShapeDownToLine,
  ArrowUturnCwRight,
} from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { useLocalState } from "../../contexts/LocalStateProvider"; // Import the local state hook
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast"; // Import the Toast component

const Version = ({ version }) => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
  } = useAudioPlayer();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { updateLocalState } = useLocalState(); // Use the local state hook
  const [toastMessage, setToastMessage] = useState(null);

  const {
    audio: { src, name, duration, cover } = {},
    musicDescription,
    avatar,
    ai_response_id,
    responseType,
    regenerated,
  } = version;

  const handlePlayPause = (audioSrc, audioName) => {
    if (isPlaying && currentAudioSrc === audioSrc) {
      pauseAudio();
    } else if (currentAudioSrc === audioSrc) {
      resumeAudio();
    } else {
      playAudio(audioSrc, audioName, musicDescription, avatar);
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = async (option) => {
    if (option === "Download") {
      // // Trigger download
      // const link = document.createElement("a");
      // link.href = src;
      // link.download = `${name}.mp3`; // Adjust the extension if necessary
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      // If streaming is done, download the complete audio file
      const response = await apiService.sendRequest(
        "fetchCompleteAIResponseAudio",
        {
          ai_response_id,
        }
      );
      const { audioFile } = response;

      if (response.success) {
        const blob = new Blob([new Uint8Array(audioFile.data)], {
          type: "audio/wav",
        });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name || "audio.wav";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        gtag("event", "download_music", {
          event_category: "Music Actions",
          event_label: "Download Button",
          value: 1,
        });
      } else {
        console.log("Failed to fetch the audio file for download.");
        response?.message
          ? setToastMessage(response?.message)
          : setToastMessage("Failed to fetch the audio file for download.");
      }
    } else if (option === "Go to prompt") {
      // Update local state with the ai_response_id to trigger scroll
      updateLocalState("scrollToResponseId", ai_response_id);
    }
    setShowDropdown(false);
  };

  return (
    <div
      id={`version-${ai_response_id}`}
      ref={dropdownRef}
      onClick={(e) => {
        e.stopPropagation();
        handlePlayPause(src, name);
      }}
      className="flex items-center m-2 self-stretch px-2 py-2 rounded-lg cursor-pointer group transition duration-500 hover:bg-[#1d1b1d] relative"
      style={
        currentAudioSrc === src
          ? {
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
            }
          : {}
      }
    >
      <div className="flex items-center relative overflow-hidden">
        <img
          src={cover}
          alt="Cover"
          className="w-8 h-8 flex justify-center items-center gap-2 object-cover rounded-md"
        />
        <button
          id="play-pause-button"
          onClick={(e) => {
            e.stopPropagation();
            handlePlayPause(src, name);
          }}
          className={`absolute inset-0 flex items-center justify-center text-white transition-transform duration-500 transform group-hover:opacity-100 ${
            isPlaying && currentAudioSrc === src
              ? "translate-y-0"
              : "translate-y-[80%] group-hover:translate-y-0"
          }`}
        >
          {isPlaying && currentAudioSrc === src ? (
            <PauseFill className="w-4 h-4" />
          ) : (
            <PlayFill className="w-4 h-4" />
          )}
        </button>
      </div>

      <div className="flex flex-col items-start ml-2">
        <span className="flex font-extralight text-sm text-white truncate max-w-36">
          {name}
        </span>
        <div
          className="flex px-1.5 rounded justify-center py-0.5 text-[8px] font-semibold"
          style={{ background: "rgba(255, 255, 255, 0.15)" }}
        >
          {regenerated
            ? "Regenerated"
            : responseType === "extension"
            ? "Extended"
            : "Original"}
        </div>
      </div>
      <div className="flex items-center gap-2 grow">
        <div className="flex flex-col justify-center items-center grow"></div>
        <span className="font-extralight text-xs text-white">{duration}</span>
      </div>
      <button
        id="dropdown-button"
        className="ml-8 rounded-md text-[#878188] hover:text-white p-1.5 bg-transparent hover:bg-[#232121] relative"
        onClick={(e) => {
          e.stopPropagation(); // Prevent triggering play/pause
          handleDropdownToggle();
        }}
      >
        <Ellipsis />
      </button>
      {showDropdown && (
        <div
          id="version-item-dropdown"
          className="absolute p-1 right-2 top-10 text-center font-normal text-sm text-[#878188] bg-[#100f10] rounded-lg border border-solid border-[#2c2a2c] z-10"
        >
          <button
            id="go-to-prompt-button"
            className="flex items-center gap-2 h-8 w-full px-2 hover:text-white rounded-lg text-center hover:bg-zinc-900"
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick("Go to prompt");
            }}
          >
            <ArrowUturnCwRight className="w-4 h-4" /> Go to prompt
          </button>
          <button
            id="download-button"
            className="flex items-center gap-2 h-8 w-full px-2 hover:text-white rounded-lg text-center hover:bg-zinc-900"
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick("Download");
            }}
          >
            <ArrowShapeDownToLine className="w-4 h-4" /> Download
          </button>
        </div>
      )}
      {toastMessage && (
        <Toast
          type="error"
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default Version;
