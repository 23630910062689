import React, { useEffect, useRef, useState } from "react";
import UserChatBox from "./UserChatBox";
import AIChatBox from "./AIChatBox";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { getCookie } from "../../utils/cookieUtils";
import axios from "axios";
import ChatBoxLoader from "./ChatBoxLoader";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";

const ChatInterface = ({ initialMessages = [], projectId }) => {
  const { localState, updateLocalState } = useLocalState();
  const [messages, setMessages] = useState(initialMessages);
  const [lastUserInput, setLastUserInput] = useState(null);
  const [isRegeneration, setIsRegeneration] = useState(false);
  const [regeneratingResponseId, setRegeneratingResponseId] = useState(null);
  const [beforeResponseLoader, setBeforeResponseLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [beforeRegenerateLoaderId, setBeforeRegenerateLoaderId] =
    useState(null);

  const messagesEndRef = useRef(null);
  const { playAudio, setIsBuffering, setStreamingSrc, setAiResponseId } =
    useAudioPlayer();
  const [loadingResponseId, setLoadingResponseId] = useState(null);
  const [streamingResponseId, setStreamingResponseId] = useState(null);

  const userId = getCookie("user_id");

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const filterAIResponsesWithAudio = (messages) => {
    return messages.filter((msg) => msg.sender === "Wubble" && msg.audio);
  };

  const scrollToMessage = (responseId) => {
    const messageElement = document.getElementById(responseId);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: "smooth" });
      updateLocalState("scrollToResponseId", null);
    }
  };

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  useEffect(() => {
    const fetchProjectSetByUser = async () => {
      try {
        const response = await apiService.sendRequest("fetchProjectSetByUser", {
          project_id: projectId,
        });
        if (response.success) {
          updateLocalState("projectNameSetByUser", response.set_by_user);
        }
      } catch (error) {
        console.error("Error fetching project set_by_user status:", error);
      }
    };

    fetchProjectSetByUser();
  }, [projectId]);

  useEffect(() => {
    if (localState.scrollToResponseId) {
      scrollToMessage(localState.scrollToResponseId);
    }
  }, [localState.scrollToResponseId]);

  useEffect(() => {
    const aiResponsesWithAudio = filterAIResponsesWithAudio(messages);
    updateLocalState("versionHistory", aiResponsesWithAudio);
  }, [messages, localState.newMessage]);

  const formatTime = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isRegeneration, beforeResponseLoader, localState.newMessage]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (localState.textInputHeight > 24) {
      setScrollHeight(localState.textInputHeight);
    } else setScrollHeight(0);
  }, [localState.textInputHeight]);

  useEffect(() => {
    if (
      localState.newMessage &&
      !isRegeneration &&
      localState.newMessage?.get("project_id") === projectId
    ) {
      const formData = localState.newMessage;
      const messageObject = {};

      // Extract data from FormData
      formData.forEach((value, key) => {
        if (key === "media") {
          // Convert the value (which should be a File or Blob) to a file object
          const file = formData.get("media");

          messageObject.file = {
            name: formData.get("file_name"),
            type: formData.get("mime_type"),
            content: file, // Store the actual file object
          };
        } else if (key === "message_id") {
          messageObject.id = value;
        } else if (key === "sentAt") {
          messageObject.sentAt = value;
        } else if (key === "prompt") {
          messageObject.prompt = value;
        } else {
          messageObject[key] = value;
        }
      });

      // Add additional fields to the message object
      messageObject.sender = "User";
      messageObject.text = messageObject.prompt || "";
      messageObject.avatar = "https://via.placeholder.com/50";

      // Avoid adding duplicate messages
      if (!messages.some((msg) => msg.id === Number(messageObject.id))) {
        // console.log("Adding new message:", messageObject);

        setMessages((prevMessages) => [...prevMessages, messageObject]);
        scrollToBottom();
      } else {
        // console.log("Duplicate message detected:", messageObject);
      }

      // console.log(messages);

      // Update last user input and reset new message
      setLastUserInput(messageObject);
      updateLocalState("newMessage", null);

      // Generate requestId
      const requestId = `${Date.now()}-${Math.random()
        .toString(36)
        .slice(2, 11)}`;

      // Append requestId to the existing FormData
      formData.append("requestId", requestId);
      setTimeout(() => {
        setBeforeResponseLoader(true);
      }, 300);
      updateLocalState("responseInProgress", true);
      // Send AI response request with updated FormData
      sendAiResponseRequest(formData);
    }
  }, [localState.newMessage, isRegeneration]);

  useEffect(() => {
    // If lastUserInput is null, retrieve it from the last message in messages
    if (!lastUserInput) {
      const lastUserMessage = messages.findLast((msg) => msg.sender === "User");

      if (lastUserMessage) {
        const populatedLastUserInput = {
          ...lastUserMessage,
          user_id: userId, // Get userId from the cookie
          project_id: projectId, // Get projectId from props
          prompt: lastUserMessage.text, // Use text as the prompt
          userInput: {
            image_bytes: null, // Default to null
            audio_bytes: null, // Default to null
            video_bytes: null, // Default to null
          },
        };
        setLastUserInput(populatedLastUserInput);
      }
    }
  }, [messages, lastUserInput]);

  useEffect(() => {
    const lastMessageWithAudio = [...messages]
      .reverse()
      .find((msg) => msg.audio);

    if (lastMessageWithAudio) {
      const detailsData = {
        name: lastMessageWithAudio.audio.name,
        description: lastMessageWithAudio.musicDescription,
        coverImage: lastMessageWithAudio.avatar,
        duration: lastMessageWithAudio.audio.duration,
        audioSrc: lastMessageWithAudio.audio.src,
        ai_response_id: lastMessageWithAudio.ai_response_id,
        projectId: lastMessageWithAudio.projectId,
      };

      updateLocalState("currentSongDetails", detailsData);
    }
  }, []);

  useEffect(() => {
    // console.log("lastUserInput1", lastUserInput);

    if (isRegeneration && lastUserInput && regeneratingResponseId) {
      // console.log("Regenerating response...");

      const requestId = `${Date.now()}-${Math.random()
        .toString(36)
        .slice(2, 11)}`;

      // Create FormData for regeneration request
      // both if newly regenerated or after reload regeneerated response are both going to have
      // file.conent.data and we will send that to the backend in this step
      const formData = new FormData();

      if (lastUserInput.file) {
        Object.keys(lastUserInput).forEach((key) => {
          if (key === "file") {
            // Convert the Buffer data back to a Blob
            const fileBuffer = new Uint8Array(
              lastUserInput.file?.content?.data
            );
            const fileBlob = new Blob([fileBuffer], {
              type: lastUserInput.file.type,
            });

            // Append the Blob to FormData as 'media'
            formData.append("media", fileBlob, lastUserInput.file.name);
          } else {
            formData.append(key, lastUserInput[key]);
          }
        });
      } else {
        Object.keys(lastUserInput).forEach((key) => {
          formData.append(key, lastUserInput[key]);
        });
      }

      // Append additional fields
      formData.append("message_id", lastUserInput.id);
      formData.append("requestId", requestId);
      formData.append("regeneration", true);
      formData.append("regeneratingResponseId", regeneratingResponseId);

      // setBeforeResponseLoader(true);
      updateLocalState("responseInProgress", true);
      sendAiResponseRequest(formData);
      setIsRegeneration(false);
      setRegeneratingResponseId(null);
    }
  }, [isRegeneration, lastUserInput, regeneratingResponseId]);

  const sendAiResponseRequest = (requestPayload) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/ai-response`,
        requestPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(async (response) => {
        const data = response.data;

        // console.log("Response data:", data);

        if (data.success) {
          let aiResponse = {
            sender: "Wubble",
            text: data.data.generatedText,
            ai_response_id: data.data?.responseId,
            parent_response_id: data.data?.parentResponseId,
            regenerated: requestPayload.get("regeneration") === true,
          };
          setBeforeResponseLoader(false);
          setBeforeRegenerateLoaderId(null);

          // console.log("AI Response before:", aiResponse);

          const requestId = requestPayload.get("requestId");

          if (data?.data?.songTitle || data?.data?.extendAudio) {
            const audioStreamUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/audio-stream?requestId=${requestId}`;

            aiResponse = {
              ...aiResponse,
              avatar: `data:image/png;base64,${data.data.coverImage?.data}`,
              audio: {
                name: data.data?.songTitle,
                duration: formatTime(data.data?.music_duration),
                src: audioStreamUrl,
                cover: `data:image/png;base64,${data.data.coverImage?.data}`,
              },
              actions: ["Extend duration", "Change genre"],
              musicCaption: data.data?.musicCaption,
              coverDescription: data.data?.coverDescription,
              ai_response_id: data.data?.responseId,
              projectId: data.data?.projectId,
              musicDescription: data.data.musicDescription,
              parent_response_id: data.data?.parentResponseId,
              responseType: data.data?.responseType,
              regenerated: data.data?.regenerated,
            };

            const detailsData = {
              name: aiResponse.audio.name,
              description: aiResponse.musicDescription,
              coverImage: aiResponse.avatar,
              duration: aiResponse.audio.duration,
              audioSrc: aiResponse.audio.src,
              ai_response_id: aiResponse.ai_response_id,
              projectId: aiResponse.projectId,
              responseType: aiResponse.responseType,
              regenerated: aiResponse.regenerated,
            };

            updateLocalState("currentSongDetails", detailsData);

            updateLocalState("newSongGenerated", true);

            // playAudioWithLoadingIndicator(
            //   audioStreamUrl,
            //   data.data.songTitle,
            //   aiResponse.musicDescription,
            //   aiResponse.avatar,
            //   aiResponse.ai_response_id,
            //   aiResponse.audio.duration
            // );
            playAudioWithLoadingIndicator(
              audioStreamUrl,
              data.data.songTitle,
              aiResponse.musicDescription,
              aiResponse.avatar,
              aiResponse.ai_response_id,
              aiResponse.audio.duration
            );
          } else {
            updateLocalState("responseInProgress", false);
          }

          setMessages((prevMessages) => {
            // Add the new AI response to the previous messages
            const updatedMessages = [...prevMessages, aiResponse];

            // Group AI messages while keeping user messages intact
            const groupedMessages = groupRelatedMessages(updatedMessages);

            // Flatten the grouped messages array and return for state update
            return groupedMessages.flat();
          });

          setBeforeResponseLoader(false);

          scrollToBottom();

          if (data?.data?.songTitle && !localState.projectNameSetByUser) {
            const updateProjectNamePayload = {
              projectId,
              project_name: data?.data?.songTitle,
              set_by_user: false,
            };
            await apiService.sendRequest(
              "updateProjectName",
              updateProjectNamePayload
            );
            updateLocalState("projectName", data?.data?.songTitle);
            updateLocalState("projectNameUpdated", true);
          }
        } else {
          console.error("Error: AI response generation failed.");
        }
        if (isRegeneration) {
          setIsRegeneration(false);
          setRegeneratingResponseId(null);
        }
      })
      .catch((error) => {
        console.error("Error sending AI response request:", error);
        if (error.response?.status === 504 || error.response?.status === 500) {
          // Handle 504 Gateway Timeout explicitly
          setToastMessage(
            "The server is currently unavailable. Please try again later."
          );
        } else {
          setToastMessage("An error occurred. Please try again.");
        }
        setBeforeResponseLoader(false);
        updateLocalState("responseInProgress", false);

        if (isRegeneration) {
          setIsRegeneration(false);
          setRegeneratingResponseId(null);
        }
      });

    updateLocalState("newMessage", null);
  };

  // const playAudioWithLoadingIndicator = (
  //   audioStreamUrl,
  //   title,
  //   description,
  //   avatar,
  //   aiResponseId,
  //   duration
  // ) => {
  //   setLoadingResponseId(aiResponseId);
  //   setStreamingResponseId(aiResponseId);
  //   setStreamingSrc(audioStreamUrl);
  //   setAiResponseId(aiResponseId);
  //   let accumulatedData = []; // Buffer for accumulating chunks
  //   let accumulatedLength = 0; // Track total length of accumulated data
  //   const minBufferLength = 4096;

  //   setIsBuffering(true);

  //   fetch(audioStreamUrl)
  //     .then(async (response) => {
  //       const reader = response.body.getReader();
  //       let firstChunkReceived = false;
  //       while (true) {
  //         const { done, value } = await reader.read();

  //         if (done) {
  //           setStreamingResponseId(null);
  //           setStreamingSrc(null);
  //           setIsBuffering(false);
  //           updateLocalState("responseInProgress", false);
  //           break;
  //         }

  //         if (value) {
  //           // Accumulate the data chunks
  //           accumulatedData.push(value);
  //           accumulatedLength += value.byteLength;

  //           if (!firstChunkReceived && accumulatedLength >= minBufferLength) {
  //             firstChunkReceived = true;
  //             setLoadingResponseId(null);
  //             setBeforeResponseLoader(false);
  //             playAudio(audioStreamUrl, title, description, avatar, duration);

  //             updateLocalState("isDetailsPanelOpen", true);
  //             // Clear the buffer to free up memory if not needed
  //             accumulatedData = null;
  //             continue;
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error streaming audio:", error);
  //       setLoadingResponseId(null);
  //       setStreamingResponseId(null);
  //       setStreamingSrc(null);
  //       setIsBuffering(false);
  //     });
  // };

  const playAudioWithLoadingIndicator = (
    audioStreamUrl,
    title,
    description,
    avatar,
    aiResponseId,
    duration
  ) => {
    setLoadingResponseId(aiResponseId);
    setStreamingResponseId(aiResponseId);
    setStreamingSrc(audioStreamUrl);
    setAiResponseId(aiResponseId);

    setIsBuffering(true);

    fetch(audioStreamUrl)
      .then(async (response) => {
        const reader = response.body.getReader();
        let firstChunkReceived = false;
        let chunkCounter = 0; // Counter to track number of chunks received
        const minChunks = 8;

        while (true) {
          const { done, value } = await reader.read();

          // console.log("Received chunk:", value);

          if (done) {
            setStreamingResponseId(null);
            setStreamingSrc(null);
            setIsBuffering(false);
            updateLocalState("responseInProgress", false);
            break;
          }

          if (value) {
            chunkCounter++;

            if (!firstChunkReceived && chunkCounter >= minChunks) {
              firstChunkReceived = true;
              setLoadingResponseId(null);
              setBeforeResponseLoader(false);
              scrollToBottom();
              playAudio(audioStreamUrl, title, description, avatar, duration);

              pageWidth >= 768 && updateLocalState("isDetailsPanelOpen", true);

              continue;
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error streaming audio:", error);
        setLoadingResponseId(null);
        setStreamingResponseId(null);
        setStreamingSrc(null);
        setIsBuffering(false);
      });
  };

  const handleRegenerate = (aiResponseId) => {
    if (streamingResponseId === aiResponseId) {
      setToastMessage("Please wait for the current response to finish.");
      return;
    }
    if (!isRegeneration) {
      // console.log("Regenerating response with ID:", aiResponseId);

      setIsRegeneration(true);
      setRegeneratingResponseId(aiResponseId);
      setBeforeRegenerateLoaderId(aiResponseId);
      // setLoadingResponseId(aiResponseId);
    }
  };

  // Group related AI responses based on parent_response_id
  const groupRelatedMessages = (messages) => {
    const responseMap = {};

    // Step 1: Group AI messages by their parent_response_id or ai_response_id
    messages.forEach((msg) => {
      if (msg.sender === "Wubble") {
        const groupId = msg.parent_response_id
          ? msg.parent_response_id + "sksres"
          : msg.ai_response_id;

        if (!responseMap[groupId]) {
          responseMap[groupId] = [];
        }

        responseMap[groupId].push(msg);
      }
    });

    // Step 2: Consolidate the grouped messages, while maintaining order
    const groupedMessages = messages.reduce((acc, msg) => {
      if (msg.sender === "User") {
        acc.push(msg); // Directly add user messages without grouping
      } else if (msg.sender === "Wubble") {
        const groupId = msg.parent_response_id
          ? msg.parent_response_id + "sksres"
          : msg.ai_response_id;
        if (responseMap[groupId] && responseMap[groupId].length > 0) {
          acc.push(responseMap[groupId]);
          delete responseMap[groupId]; // Prevent re-adding the same group
        }
      }
      return acc;
    }, []);

    // console.log("Grouped Messages:", groupedMessages);

    // console.log("Messages:", messages);
    return groupedMessages;
  };

  const groupedMessages = groupRelatedMessages(messages);

  return (
    <>
      <div
        className={`flex-1 flex flex-col ${
          isAudioPlaying
            ? `max-h-[calc(100vh-14rem-${scrollHeight / 13}rem)]`
            : `max-h-[calc(100vh-9.6rem-${scrollHeight / 13}rem)]`
        } scrollable-content overflow-y-auto items-center justify-top w-full z-10 pb-8`}
      >
        {groupedMessages.map((groupOrMessage, index) => {
          if (Array.isArray(groupOrMessage)) {
            // This is a group of AI messages
            const mainMessage = groupOrMessage[0];
            const relatedMessages = groupOrMessage.slice(1);

            const isLastMessage =
              index === groupedMessages.length - 1 &&
              groupOrMessage.includes(messages[messages.length - 1]);

            return (
              <AIChatBox
                key={mainMessage.ai_response_id}
                message={mainMessage}
                isLastMessage={isLastMessage}
                loadingResponseId={loadingResponseId}
                beforeRegenerateLoaderId={beforeRegenerateLoaderId}
                streamingResponseId={streamingResponseId}
                onRegenerate={handleRegenerate}
                relatedMessages={relatedMessages}
                id={mainMessage.ai_response_id}
                passedFromChatInterface={true}
              />
            );
          } else {
            // This is a single user message
            return <UserChatBox key={index} message={groupOrMessage} />;
          }
        })}
        {beforeResponseLoader && (
          <div className="flex-1 mt-auto flex items-center w-full max-w-screen-lg lg:max-w-screen-md">
            <ChatBoxLoader className={"w-full"} />
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      {toastMessage && (
        <Toast
          type="info"
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </>
  );
};

export default ChatInterface;
