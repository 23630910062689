import React, { useRef } from "react";
import { ArrowShapeUpFromLine } from "@gravity-ui/icons";

const ModalContent = ({ title, fileTypes, handleFileChange }) => {
  const dropRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFileChange({ target: { files } });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <h2 className="text-xl font-medium mb-3 text-left max-w-80 w-screen px-5 pt-5">
        {title}
      </h2>
      <div className="w-full border-t border-t-[#3c393c] mb-3"></div>
      <div
        ref={dropRef}
        className="border-dashed m-3 border-2 border-[#3c393c] px-5 py-5 rounded-md text-center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <ArrowShapeUpFromLine className="w-[35px] h-[34.999515533447266px] text-white mx-auto mb-[12px]" />
        <p className="mb-[4px] text-sm">
          Drop a file here or{" "}
          <label
            htmlFor="fileInput"
            className="text-white cursor-pointer underline"
          >
            Browse
          </label>
        </p>
        <input
          type="file"
          accept={fileTypes.join(",")}
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />
        <p className="text-gray-400 font-light text-sm">
          File supported: {fileTypes.join(", ")}
        </p>
        <p className="text-gray-400 font-light text-sm">File size: max 4 MB</p>
        <div className="flex space-x-4 justify-center mt-4">
          <button className="h-8 flex justify-center items-center font-medium text-sm text-[#878188] bg-[#100f10] px-3 rounded-[40px] border border-solid border-[#2c2a2c]">
            <img src="/dropbox.png" alt="Dropbox" className="w-4 h-4 mr-2" />
            Upload via Dropbox
          </button>
          <button className="h-8 flex justify-center items-center font-medium text-sm text-[#878188] bg-[#100f10] px-3 rounded-[40px] border border-solid border-[#2c2a2c]">
            <img
              src="/GDrive.png"
              alt="Google Drive"
              className="w-5 h-5 mr-2"
            />
            Upload via Google Drive
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
