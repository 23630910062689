// Conditional rendering function to handle components based on the sidebar state.

export const conditionalRender = (
  item,
  isShortSidebar,
  setIsShortSidebar,
  renderComponents,
  isLoggedIn,
  location
) => {
  if (item.headerConditionalJson) {
    const headerJsonFileName = isLoggedIn
      ? item.headerConditionalJson.afterLogin
      : item.headerConditionalJson.beforeLogin;

    if (headerJsonFileName) {
      const headerJsonFile = require(`../jsonFiles/${headerJsonFileName}`);
      return renderComponents(headerJsonFile);
    }
  }

  if (item.footerConditionalJson) {
    const footerJsonFileName = isLoggedIn
      ? item.footerConditionalJson.afterLogin
      : item.footerConditionalJson.beforeLogin;

    if (footerJsonFileName) {
      const footerJsonFile = require(`../jsonFiles/${footerJsonFileName}`);
      return renderComponents(footerJsonFile);
    }
  }

  // Check if the item has a conditionalJson key
  if (item.conditionalJson) {
    // Determine which JSON file to load based on the sidebar state and login status
    const jsonFileName = isLoggedIn
      ? isShortSidebar
        ? item.conditionalJson.trueJsonAfterLogin
        : item.conditionalJson.falseJsonAfterLogin
      : isShortSidebar
      ? item.conditionalJson.trueJson
      : item.conditionalJson.falseJson;

    // If the sidebar is not short and there is no falseJson, skip rendering
    if (!isShortSidebar && !jsonFileName) {
      return null;
    }

    // Load and render the appropriate sidebar JSON file if it exists
    if (jsonFileName) {
      const jsonFile = require(`../jsonFiles/${jsonFileName}`);
      return renderComponents(jsonFile);
    }
  }

  // Handle inputJson key to import JSON pages into other JSON pages
  if (item.inputJson && location.pathname !== "/chatPage") {
    const jsonFile = require(`../jsonFiles/${item.inputJson}`);
    return renderComponents(jsonFile);
  }

  const { name, props } = item;

  // Add the onClick function to the specific button to toggle the sidebar state
  if (name === "button" && props.trigger === "toggleSidebar") {
    props.onClick = () => setIsShortSidebar((prev) => !prev);
  }

  // Return null if no specific rendering is required
  return null;
};
